export interface PartnerRate {
    name: string;
    rate: number;
    businessId?: string; // Optional unique identifier
  }
  
  export const PARTNER_RATES: Record<string, PartnerRate> = {
    'elev8tion': { name: 'Elev8tion Fitness', rate: 0.5, businessId: '6d48e022-a532-4ef8-8f6e-869b9fbe4031' },
    'legacy': { name: 'Legacy Wynwood', rate: 0.4, businessId: '1cb41171-6a86-4ecc-8445-67a05ae94e43' },
    'omega': { name: 'Omega Wellness Club', rate: 0.5, businessId: '3490c405-dce9-4475-bb30-3fbace35d5da' },
    'gotham': { name: 'Gotham Gym Miami', rate: 0.35, businessId: '75e08a05-7671-4f46-9da5-8ad72288aaae' },
    'purepower': { name: 'Purepower Cycle Midtown Miami', rate: 0.5, businessId: 'b8af00e0-8d6e-4b60-a9bc-bfbde5ed292b' },
    'ufc': { name: 'UFC GYM Midtown', rate: 0.35, businessId: '5870fdbc-41ee-4a2c-baaa-1a84babffcf3' },
    'prestige': { name: 'Prestige Miami Fitness Club', rate: 0.5, businessId: 'a0df038b-baf0-4bbe-9a59-64f2b0b1f8e0' },
  };
  
  // Default rate if no match is found
  export const DEFAULT_PARTNER_RATE = 0.5;
  
  // Helper function to get rate by business name or ID
  export function getPartnerRate(businessNameOrId: string): number {
    // Normalize input for matching
    const normalizedInput = businessNameOrId.toLowerCase().trim();
    
    // Try direct matching
    if (PARTNER_RATES[normalizedInput]) {
      return PARTNER_RATES[normalizedInput].rate;
    }
    
    // Try fuzzy matching against name and businessId
    for (const key in PARTNER_RATES) {
      const partner = PARTNER_RATES[key];
      if (
        partner.name.toLowerCase().includes(normalizedInput) ||
        (partner.businessId && partner.businessId.toLowerCase().includes(normalizedInput))
      ) {
        return partner.rate;
      }
    }
    
    // Return default rate if no match found
    return DEFAULT_PARTNER_RATE;
  }