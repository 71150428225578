import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { validateDiscountCodeCall, recordDiscountCodeUsageCall, cancelSubscriptionImmediatelyCall, deleteMemberSettingsCall } from "../../api/apiOperations";
import { subscribeToProduct } from "../../api/apiEndpoints";
import { setAppliedDiscount, clearAppliedDiscount, setDiscountError, setSubscriptionId, setClientSecretStripe } from "../../slices/appSlice";
import {
  Container,
  InputRow,
  DiscountInput,
  ApplyButton,
  DiscountMessage,
  AppliedDiscount,
  RemoveButton,
} from "./styles";
import { getAuth } from "firebase/auth";

type RootState = {
  appData: {
    appliedDiscount: {
      code: string;
      type: string;
      amount: number;
      description?: string;
      stripePromoId?: string | null;
    } | null;
    subscriptionId: string | null;
    selectedProduct?: {
      businessProductId: string;
    };
  };
};

const DiscountCodeInput: React.FC = () => {
  const [discountCode, setDiscountCode] = useState<string>("");
  const [isApplying, setIsApplying] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useDispatch();

  const { appliedDiscount, subscriptionId, selectedProduct } = useSelector((state: RootState) => state.appData);

  // Updated DiscountCodeInput.tsx
const handleApplyDiscount = async () => {
  if (!discountCode.trim()) return;

  setIsApplying(true);
  setErrorMessage("");

  try {
    // 1. Validate the discount code
    const result = await validateDiscountCodeCall(discountCode.trim(), subscriptionId ?? undefined);
    // console.log("Discount validation result:", result);

    if (result.valid) {
      // 2. Cancel old subscription if it exists
      if (subscriptionId) {
        // console.log("Canceling old subscription:", subscriptionId);
        await cancelSubscriptionImmediatelyCall(subscriptionId);
        
        // Clear the client secret in Redux to force re-initialization
        dispatch(setClientSecretStripe('')); 
      }

      // 3. Create new subscription with promo
      if (!selectedProduct?.businessProductId) {
        throw new Error("No selected product found while applying discount.");
      }
      
      const response = await subscribeToProduct(
        selectedProduct.businessProductId,
        result.stripePromoId!
      );
      
      if (response?.stripeSubscriptionId) {
        dispatch(setSubscriptionId(response.stripeSubscriptionId));
      
        // CRITICAL: Update the client secret in Redux
        if (response.clientSecret) {
          // console.log("Setting new client secret:", response.clientSecret.substring(0, 10) + "...");
          dispatch(setClientSecretStripe(response.clientSecret));
        }
      
        // Update the discount information in Redux
        dispatch(setAppliedDiscount({
          code: result.code,
          type: result.type,
          amount: result.amount,
          description: result.description,
          stripePromoId: result.stripePromoId,
        }));
        
        setDiscountCode("");
      } else {
        // console.error("Subscription creation failed:", response);
        setErrorMessage("Could not create a new subscription. Please try again.");
      }
    } else {
      setErrorMessage(result.message || "Invalid discount code");
      dispatch(setDiscountError(result.message || "Invalid discount code"));
    }
  } catch (error) {
    // console.error("Error validating or applying discount:", error);
    setErrorMessage("Error validating discount code");
    dispatch(setDiscountError("Error validating discount code"));
  } finally {
    setIsApplying(false);
  }
};

  // Record discount usage after subscription is created
  useEffect(() => {
    if (appliedDiscount && subscriptionId) {
      const user = getAuth().currentUser;
      const userEmail = user?.email;
  
      if (!userEmail) {
        console.warn("No userEmail found. Skipping discount usage recording.");
        return;
      }
  
      const recordUsage = async () => {
        try {
          console.log("Applied Discount:", appliedDiscount.code)
          console.log("SubscriptionId:", subscriptionId) 
          console.log("userId:", userEmail)
          const result = await recordDiscountCodeUsageCall(appliedDiscount.code, subscriptionId, userEmail);
          if (result.success) {
            console.log("Successfully recorded discount code usage");
          } else {
            console.error("Failed to record discount code usage: API returned success: false");
          }
        } catch (error) {
          console.error("Failed to record discount code usage:", error);
        }
      };
  
      recordUsage();
    }
  }, [appliedDiscount, subscriptionId]);
  

  const handleRemoveDiscount = () => {
    dispatch(clearAppliedDiscount());
  };
  
  

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleApplyDiscount();
    }
  };

  const getDiscountText = () => {
    if (!appliedDiscount) return "";

    return appliedDiscount.type === "percentage"
      ? `${appliedDiscount.amount}% off`
      : `$${appliedDiscount.amount.toFixed(2)} off`;
  };

  return (
    <Container>
      {appliedDiscount ? (
        <AppliedDiscount>
          <div>
            <strong>Discount applied:</strong> {appliedDiscount.code} ({getDiscountText()})
            {appliedDiscount.description && ` - ${appliedDiscount.description}`}
          </div>
          <RemoveButton onClick={handleRemoveDiscount}>Remove</RemoveButton>
        </AppliedDiscount>
      ) : (
        <>
          <InputRow>
            <DiscountInput
              placeholder="Discount Code"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
              onKeyPress={handleKeyPress}
              disabled={isApplying}
            />
            <ApplyButton
              onClick={handleApplyDiscount}
              disabled={!discountCode.trim() || isApplying}
            >
              {isApplying ? <CircularProgress size={20} color="inherit" /> : "Apply"}
            </ApplyButton>
          </InputRow>
          {errorMessage && <DiscountMessage isError>{errorMessage}</DiscountMessage>}
        </>
      )}
    </Container>
  );
};

export default DiscountCodeInput;