import styled, { keyframes } from 'styled-components';
import theme from '../../../utils/theme';

const { templateColors } = theme;

// Define the scrolling animation
const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

export const LogoSliderContainer = styled.div`
  background-color: ${templateColors.lightGrey};
  overflow: hidden;
  padding: 10px 0 20px; /* Reduced padding, especially on top */
  width: 100%;
  position: relative;
  margin-top: -30px; /* Negative margin to pull it closer to hero section */

  @media (max-width: 768px) {
    padding: 5px 0 15px;
    margin-top: -20px;
  }
`;


export const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const LogoBand = styled.div`
  display: flex;
  animation: ${scroll} 30s linear infinite;
  width: fit-content;
`;

// We duplicate the logos to create a seamless loop
export const LogoGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 100%;
  white-space: nowrap;
`;

export const LogoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const LogoImage = styled.img`
  height: 30px;
  filter: grayscale(100%);
  opacity: 0.6;
  transition: all 0.3s ease;
  
  &:hover {
    filter: grayscale(0);
    opacity: 1;
  }
  
  @media (max-width: 768px) {
    height: 25px;
  }
`;