import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import EOMButton from "../EOMButton";
import {
  MEMBERS_LIST,
  NO_MEMBERS_TO_SHOW,
  USER,
  EMAIL,
  MEMBERSHIP_EXPIRATION,
  MEMBERSHIP_TYPE,
  CREATE_PROFILE_CARD,
  PROFILE_CARD_CREATED,
  PHONE,
} from "../../utils/strings";
import { isTabletOrMobileSize } from "../../utils/queries";
import theme from "../../utils/theme";
import Line from "../Line";
import { BusinessDashboardUser } from "../../utils/types";
import { daysUntilExpiration, isMembershipCancelled, isExpired } from "../../utils/utils";
import {
  Container,
  Background,
  Title,
  Subcontainer,
  Header,
  Tile,
  Row,
  TileTwo,
  ScrollBox,
  TileTree,
  TileType,
  ExpirationStatus,
  Cell,
} from "./styles";

import OnboardingCheckbox from "../OnboardingCheckbox";
import ConversionCheckbox from "../ConversionCheckbox";
import AccountSnapshot from "../AccountSnapshot";

// Custom styled component for Members List title with less bottom margin
const MembersListTitle = styled(Title)`
  margin-bottom: 0; /* Reduce space between title and table */
`;

interface DashboardListingProps {
  handleOpenGymCardForm: () => void;
}

const DashboardListing = ({ handleOpenGymCardForm }: DashboardListingProps) => {
  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });
  // Add state to track dashboard data
  const [dashboardData, setDashboardData] = useState<BusinessDashboardUser[]>([]);

  const { businessDashboardData, partnerGymInfo } = useSelector(
    (state: RootState) => state.appData || {}
  );

  // Initialize the dashboardData state with businessDashboardData
  useEffect(() => {
    if (businessDashboardData?.length > 0) {
      setDashboardData(businessDashboardData);
    }
  }, [businessDashboardData]);

  const profileCardCreated = partnerGymInfo?.products?.length > 1;

  // Function to refresh data - keeping the original implementation
  const refreshDashboardData = useCallback(() => {
    console.log("Refreshing dashboard data...");
    window.location.reload();
  }, []);

  // Function to update conversion status without a full page reload
  const handleConversionUpdate = useCallback((membershipId: string, isConverted: boolean, convertedAt: string | null) => {
    setDashboardData(prevData => 
      prevData.map(user => 
        user.membershipId === membershipId 
          ? { ...user, isConverted, convertedAt } 
          : user
      )
    );
  }, []);

  return (
    <Container>
      <Background>
        <Header>
          <Title>Account Snapshot</Title>
          {!profileCardCreated && (
            <EOMButton
              title={CREATE_PROFILE_CARD}
              onPress={handleOpenGymCardForm}
              buttonStyle={{
                backgroundColor: theme.templateColors.lightYellow,
                borderRadius: 3,
                width: isTabletOrMobile ? 110 : 150,
              }}
              titleStyle={{
                color: "black",
                fontSize: isTabletOrMobile ? 10 : 13,
                textAlign: "center",
              }}
            />
          )}
        </Header>
        
        {/* AccountSnapshot using the updated dashboardData instead of businessDashboardData */}
        <AccountSnapshot 
          businessDashboardData={dashboardData || []} 
          partnerName={partnerGymInfo?.name || ''} 
          showTitle={false} 
        />
        
        {/* Members List with minimal spacing */}
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: '30px' }}>
          <MembersListTitle>{MEMBERS_LIST}</MembersListTitle>
        </div>
        
        <Subcontainer style={{ marginTop: '5px' }}>
          <Row isHeader={true}>
            <TileTwo>{USER}</TileTwo>
            <TileTwo>{EMAIL}</TileTwo>
            <TileTwo>{PHONE}</TileTwo>
            <TileTwo>{MEMBERSHIP_EXPIRATION}</TileTwo>
            <TileTwo>{MEMBERSHIP_TYPE}</TileTwo>
            <TileTwo>Member Onboarded</TileTwo>
            <TileTwo>Member Converted</TileTwo>
          </Row>
          {dashboardData?.length > 0 ? (
            <ScrollBox>
              {dashboardData?.map(
                ({
                  firstName,
                  lastName,
                  email,
                  phone,
                  expiresAt,
                  productName,
                  scheduledCancelAt,
                  membershipId,
                  isOnboarded,
                  onboardedAt,
                  isConverted,
                  convertedAt
                }: BusinessDashboardUser) => (
                  <div key={uuidv4()}>
                    <Line />
                    <Row key={uuidv4()}>
  <Cell data-label={USER}>
    <Tile>
      {firstName} {lastName}
    </Tile>
  </Cell>
  <Cell data-label={EMAIL}>
    <Tile>{email}</Tile>
  </Cell>
  <Cell data-label={PHONE}>
    <Tile>{phone && phone !== "null" && phone !== "undefined" ? phone : "-"}</Tile>
  </Cell>
  <Cell data-label={MEMBERSHIP_EXPIRATION}>
    {isMembershipCancelled(scheduledCancelAt) || isExpired(expiresAt) ? (
      <ExpirationStatus daysLeft={null}>Expired</ExpirationStatus>
    ) : (
      <ExpirationStatus daysLeft={parseInt(daysUntilExpiration(expiresAt))}>
        {daysUntilExpiration(expiresAt)}
      </ExpirationStatus>
    )}
  </Cell>
  <Cell data-label={MEMBERSHIP_TYPE}>
    <TileType membershipType={productName}>
      {productName === "basic" ? "Hopper" : "Power"}
    </TileType>
  </Cell>
  <Cell data-label="Member Onboarded">
    <OnboardingCheckbox
      membershipId={membershipId}
      isOnboarded={isOnboarded || false}
      onboardedAt={onboardedAt}
      isExpired={isExpired(expiresAt)}
      onUpdate={refreshDashboardData}
    />
  </Cell>
  <Cell data-label="Member Converted">
    <ConversionCheckbox
      membershipId={membershipId}
      isConverted={isConverted || false}
      convertedAt={convertedAt}
      isExpired={isExpired(expiresAt)}
      onUpdate={refreshDashboardData}
      onConversionChange={handleConversionUpdate}
    />
  </Cell>
</Row>
                  </div>
                )
              )}
            </ScrollBox>
          ) : (
            <>
              <Line />
              <TileTree>{NO_MEMBERS_TO_SHOW}</TileTree>
            </>
          )}
        </Subcontainer>
      </Background>
    </Container>
  );
};

export default DashboardListing;