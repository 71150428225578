import React from 'react';
import {
  LogoSliderContainer,
  SliderWrapper,
  LogoBand,
  LogoGroup,
  LogoItem,
  LogoImage
} from './styles';

interface Logo {
  id: number;
  name: string;
  imageUrl: string;
}

interface LogoSliderProps {
  logos: Logo[];
}

const LogoSlider: React.FC<LogoSliderProps> = ({ logos }) => {
  return (
    <LogoSliderContainer>
      <SliderWrapper>
        <LogoBand>
          {/* First set of logos */}
          <LogoGroup>
            {logos.map((logo) => (
              <LogoItem key={logo.id}>
                <LogoImage 
                  src={logo.imageUrl} 
                  alt={`${logo.name} logo`} 
                />
              </LogoItem>
            ))}
          </LogoGroup>
          
          {/* Duplicate set of logos to create continuous effect */}
          <LogoGroup>
            {logos.map((logo) => (
              <LogoItem key={`dup-${logo.id}`}>
                <LogoImage 
                  src={logo.imageUrl} 
                  alt={`${logo.name} logo`} 
                />
              </LogoItem>
            ))}
          </LogoGroup>
        </LogoBand>
      </SliderWrapper>
    </LogoSliderContainer>
  );
};

export default LogoSlider;