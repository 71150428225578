import styled from "styled-components";
import theme from "../../utils/theme";

const { colors, templateColors } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  height: 100%;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const GalleryContainer = styled.div`
  position: relative;
  width: 100%;
  height: 240px;
  overflow: hidden;
`;

export const GymImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: transform 0.5s ease;
`;

export const GalleryNavButton = styled.button<{ left?: boolean; right?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${props => props.left ? 'left: 10px;' : ''}
  ${props => props.right ? 'right: 10px;' : ''}
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.7;
  z-index: 2;
  
  &:hover {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;

export const ContentContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const GymName = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 6px 0;
  color: #25252d;
`;

export const GymAddress = styled.div`
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

export const FeaturesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 10px;
`;

export const FeatureTag = styled.span`
  background-color: #f5f5f5;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: #555;
`;

export const InvisibleButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
  opacity: 0;
`;

// Legacy styles needed for compatibility - these can stay but aren't used for the new design
export const Title = styled.span``;
export const Description = styled.span``;
export const Image = styled.img``;
export const GymNameTitle = styled.span``;
export const AmenitiesContainer = styled.div``;
export const AddressIcon = styled.img``;
export const AddressContainer = styled.div``;
export const Address = styled.a``;
export const SocialIconsContainer = styled.div``;
export const Price = styled.span``;
export const BottomContainer = styled.div``;
export const SocialIcon = styled.img``;
export const SocialIconRef = styled.a``;
export const PresentationBox = styled.div``;
export const DescriptionContainer = styled.div``;
export const GymRating = styled.div``;
export const RatingValue = styled.span``;
export const ReviewCount = styled.span``;
export const RatingContainer = styled.div``;
export const PriceContainer = styled.div``;
export const PriceValue = styled.span``;
export const ImageCounter = styled.div``;