import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";

import { subscribeToProduct } from "../../../api/apiEndpoints";
import PaymentForm from "../PaymentForm";
import DiscountCodeInput from "../../../components/DiscountCodeInput";
import { CircularProgress } from "@mui/material";
import { setSubscriptionId, setClientSecretStripe, clearAppliedDiscount } from "../../../slices/appSlice"; 

const Payment = () => {
  // Track all state locally to avoid Redux issues
  const [stripePromise, setStripePromise] = useState<Promise<any> | null>(null);
  const clientSecret = useSelector((state: any) => state.appData.clientSecretStripe);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [elementsKey, setElementsKey] = useState(Date.now());
  const [hasTriedSubscription, setHasTriedSubscription] = useState<boolean>(false);
  const [isTrialSubscription, setIsTrialSubscription] = useState<boolean>(false);
  const dispatch = useDispatch();

  // Get needed data from Redux
  const { selectedProduct, appliedDiscount } = useSelector((state: any) => state.appData);
  const { idToken } = useSelector((state: any) => state.auth);

  // Load Stripe on component mount
  useEffect(() => {
    const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    console.log("Loading Stripe with key:", stripeKey);
    if (stripeKey) {
      setStripePromise(loadStripe(stripeKey));
    }
  }, []);

  useEffect(() => {
    if (clientSecret) {
      setElementsKey(Date.now());
    }
  }, [clientSecret]);

  // Log when discount changes
  useEffect(() => {
    console.log("Applied discount from Redux:", appliedDiscount);
  }, [appliedDiscount]);

  useEffect(() => {
    dispatch(clearAppliedDiscount());
  }, []);

  // One-time subscription attempt using a ref to prevent loops
  useEffect(() => {
    // Only attempt once, and only if we have the necessary data and no client secret yet
    if (
      !hasTriedSubscription && 
      idToken && 
      selectedProduct?.businessProductId && 
      !clientSecret
    ) {
      const createSubscription = async () => {
        try {
          setIsLoading(true);
          setError(null);
          setHasTriedSubscription(true); // Mark that we've tried
          
          // Log BEFORE making the API call to see what we're sending
          console.log("About to create subscription with:", {
            productId: selectedProduct.businessProductId,
            discountCode: appliedDiscount?.code,
            stripePromoId: appliedDiscount?.stripePromoId
          });          
          
          // Make the API call
          const response = await subscribeToProduct(
            selectedProduct.businessProductId,
            appliedDiscount?.stripePromoId ?? null
          );
          
          console.log("Subscription response:", response);
          
          // Check if this is a trial subscription (100% off)
          if (response && response.isTrialSubscription) {
            setIsTrialSubscription(true);
            console.log("Trial subscription detected (100% off) - using SetupIntent");
          }
          
          // Extract client secret - first check direct property
          if (response && response.clientSecret) {
            if (response.stripeSubscriptionId) {
              dispatch(setSubscriptionId(response.stripeSubscriptionId));
              dispatch(setClientSecretStripe(response.clientSecret));
              console.log("Saved subscription ID to Redux:", response.stripeSubscriptionId);
            }
            setIsLoading(false);
          } else {
            // If we can't find it, show error
            console.error("No client secret in response", response);
            setError("Could not process payment. Please try again.");
            setIsLoading(false);
          }
        } catch (err: any) {
          console.error("Subscription error:", err);
          setError(`Payment setup failed: ${err?.message || "Unknown error"}`);
          setIsLoading(false);
        }
      };
      
      createSubscription();
    }
  }, [idToken, selectedProduct, hasTriedSubscription, clientSecret, appliedDiscount, dispatch]);
  
  // Function to manually retry subscription
  const retrySubscription = () => {
    setHasTriedSubscription(false);
    setError(null);
  };
  
  return (
    <div>
      {isLoading && (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <CircularProgress size={25} />
        </div>
      )}
      
      {error && !isLoading && (
        <div style={{ color: 'red', textAlign: 'center', padding: '20px' }}>
          {error}
          <div style={{ marginTop: '10px' }}>
            <button 
              onClick={retrySubscription}
              style={{ 
                padding: '8px 16px', 
                backgroundColor: '#333', 
                color: 'white', 
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              Try Again
            </button>
          </div>
        </div>
      )}
      
      {clientSecret && stripePromise && !isLoading && !error && (
        <Elements
          key={elementsKey}
          stripe={stripePromise}
          options={{
            clientSecret: clientSecret,
            appearance: {
              theme: 'flat',
              variables: {
                fontFamily: ' "Gill Sans", sans-serif',
                fontLineHeight: '1.5',
                borderRadius: '10px',
                colorBackground: '#F6F8FA',
                accessibleColorOnColorPrimary: '#262626'
              },
              rules: {
                '.Block': {
                  backgroundColor: 'var(--colorBackground)',
                  boxShadow: 'none',
                  padding: '12px'
                },
                '.Input': {
                  padding: '12px'
                },
                '.Input:disabled, .Input--invalid:disabled': {
                  color: 'lightgray'
                },
                '.Tab': {
                  padding: '10px 12px 8px 12px',
                  border: 'none'
                },
                '.Tab:hover': {
                  border: 'none',
                  boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
                },
                '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
                  border: 'none',
                  backgroundColor: '#fff',
                  boxShadow: '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
                },
                '.Label': {
                  fontWeight: '500'
                }
              }
            },
          }}
        >
          <PaymentForm isTrialSubscription={isTrialSubscription} />
        </Elements>
      )}
      
      {!clientSecret && !isLoading && !error && (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <CircularProgress size={25} />
        </div>
      )}
    </div>
  );
};

export default Payment;