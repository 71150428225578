import React, { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import {
  CHECKOUT_DONE,
  CHECKOUT_DONE_DESCRIPTION,
  COMPLETE_SUBSCRIPTION,
  GO_TO_DASHBOARD,
  PLEASE_CHECK_YOUR_CARD,
  WE_COULDNT_PROCCESS_PAYMENT,
} from "../../../utils/strings";
import { DASHBOARD_MEMBERS } from "../../../utils/routes";
import { emptyBasket } from "../../../slices/appSlice";
import { setLoggedIn, setMemberLoggedIn } from "../../../slices/authSlice";
import CustomModal from "../../../components/CustomModal";
import StatusScreen from "../../../components/StatusScreen";
import theme from "../../../utils/theme";
import { injectScript } from "../../../utils/utils";
import { Button, Container, Form, TermsCheckbox } from "./styles";
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import DiscountCodeInput from "../../../components/DiscountCodeInput";
import { sendWelcomeEmailCall, attachPaymentMethodToSubscriptionCall } from "../../../api/apiOperations";

// Updated RootState type with all needed properties
type RootState = {
  appData: {
    userExist: boolean;
    clientSecretStripe?: string;
    selectedProduct?: {
      name?: string;
    };
    userData?: { 
      email?: string;
      firstName?: string;
      lastName?: string;
    };
    businessProfile?: {
      name?: string;
    };
    basketProduct?: {
      name?: string;
    };
    appliedDiscount?: {
      code?: string;
      amount?: number;
      type?: string;
    };
  };
  auth: any;
};

interface PaymentFormProps {
  isTrialSubscription?: boolean;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ isTrialSubscription = false }) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [paymentFailure, setPaymentFailure] = useState<boolean>(false);
  const [onReadyPaymentForm, setOnReadyPaymentForm] = useState<boolean>(false);
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  // Get all Redux state at component level
  const { userExist, selectedProduct, businessProfile, basketProduct, appliedDiscount, clientSecretStripe } = useSelector((state: RootState) => state.appData);
  const userData = useSelector((state: RootState) => state.appData.userData);
  const auth = useSelector((state: RootState) => state.auth);
  const subscriptionId = useSelector((state: any) => state.appData.subscriptionId);
  
  // Check if this is a SetupIntent (trial subscription) based on client secret
  const isSetupIntent = clientSecretStripe?.startsWith('seti_');
  const effectiveIsTrialSubscription = isTrialSubscription || isSetupIntent;
  
  const disabled = isProcessing || !userExist;
  
  const handlePayBasicSubscription = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) return;
    setIsProcessing(true);
  
    try {
      console.log("Client secret type detection:", { 
        clientSecret: clientSecretStripe?.substring(0, 10) + '...',
        isSetupIntent, 
        propIsTrialSubscription: isTrialSubscription,
        effectiveIsTrialSubscription
      });
  
      if (effectiveIsTrialSubscription) {
        // For trial subscriptions (100% off), use confirmSetup
        console.log("Using confirmSetup for trial subscription");
        const { error, setupIntent } = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: `${window.location.origin}/checkout`,
          },
          redirect: "if_required"
        });
  
        if (error) {
          console.error("Setup error:", error);
          setShowStatusModal(true);
          setPaymentFailure(true);
          setIsProcessing(false);
          return;
        }
  
        if (setupIntent && setupIntent.status === "succeeded") {
          console.log("Setup succeeded:", setupIntent);
        
          const rawPaymentMethod = setupIntent.payment_method;
          const paymentMethodId = typeof rawPaymentMethod === "string" ? rawPaymentMethod : rawPaymentMethod?.id;

          if (!paymentMethodId) {
            console.error("❌ No valid payment method ID found");
            setShowStatusModal(true);
            setPaymentFailure(true);
            return;
          }

          const { success } = await attachPaymentMethodToSubscriptionCall(subscriptionId, paymentMethodId);
        
          if (!success) {
            console.error("❌ Could not attach payment method to subscription");
            setShowStatusModal(true);
            setPaymentFailure(true);
            return;
          }
        
          handleSuccessfulPayment();
        }        
      } else {
        // Regular payment flow
        console.log("Using confirmPayment for regular subscription");
        const { error, paymentIntent } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${window.location.origin}/checkout`,
          },
          redirect: "if_required",
        });
  
        if (error) {
          // Try confirmSetup as a fallback if we get an error that suggests we should
          if (error.type === 'validation_error' && 
              error.message && 
              error.message.includes('SetupIntent')) {
            console.log("Got SetupIntent error, trying confirmSetup as fallback");
            const setupResult = await stripe.confirmSetup({
              elements,
              confirmParams: {
                return_url: `${window.location.origin}/checkout`,
              },
              redirect: "if_required"
            });
            
            if (setupResult.error) {
              console.error("Fallback Setup error:", setupResult.error);
              setShowStatusModal(true);
              setPaymentFailure(true);
            } else if (setupResult.setupIntent?.status === "succeeded") {
              console.log("Fallback Setup succeeded");
              handleSuccessfulPayment();
            } else {
              setShowStatusModal(true);
              setPaymentFailure(true);
            }
            return;
          }
          
          console.error("Payment error:", error);
          setShowStatusModal(true);
          setPaymentFailure(true);
          setIsProcessing(false);
          return;
        }
  
        if (paymentIntent && paymentIntent.status === "succeeded") {
          console.log("Payment succeeded:", paymentIntent);
          handleSuccessfulPayment();
        } else {
          console.warn("Payment neither succeeded nor failed:", paymentIntent);
          setShowStatusModal(true);
          setPaymentFailure(true);
        }
      }
    } catch (err) {
      console.error("Unexpected error during payment process:", err);
      setShowStatusModal(true);
      setPaymentFailure(true);
    } finally {
      setIsProcessing(false);
    }
  };
  
  // Extract common success handling logic to a separate function
  const handleSuccessfulPayment = () => {
    setShowStatusModal(true);
    setPaymentFailure(false);
    injectScript();
  
    // Send welcome email
    try {
      console.log("UserData:", userData);
      const gymName = basketProduct?.name || businessProfile?.name || "your selected gym";
      console.log("Gym Name:", gymName)
      
      if (userData && userData.email) {
        sendWelcomeEmailCall(
          userData.email,
          userData.firstName || "New Member",
          gymName
        )
          .then(result => {
            console.log("Welcome email sent:", result);
          })
          .catch(err => {
            console.error("Failed to send welcome email:", err);
          });
      } else {
        console.warn("No user data available for welcome email");
      }
    } catch (error) {
      console.error("Error preparing welcome email:", error);
    }
  };

  const handleOpenStatusModal = () => {
    if (!paymentFailure) {
      setShowStatusModal(!showStatusModal);
      dispatch(emptyBasket());
      dispatch(setLoggedIn(true));
      dispatch(setMemberLoggedIn(true));
      navigate(DASHBOARD_MEMBERS);
    } else {
      setShowStatusModal(!showStatusModal);
    }
  };

  return (
    <Container>
      {effectiveIsTrialSubscription && (
        <div style={{ 
          marginBottom: '20px', 
          padding: '15px', 
          backgroundColor: '#f0fff4', 
          borderRadius: '5px',
          border: '1px solid #c6f6d5'
        }}>
          <h4 style={{ margin: '0 0 8px', color: '#38a169' }}>First Month Free!</h4>
          <p style={{ margin: '0', fontSize: '14px', color: '#2d3748' }}>
            Please add your payment method for future billing. You won't be charged today.
          </p>
        </div>
      )}
      
      <Form id="payment-form" onSubmit={handlePayBasicSubscription}>
        {!onReadyPaymentForm && (
          <div style={{ color: "white", paddingBottom: "20px" }}>
            <CircularProgress size={25} />
          </div>
        )}
        <PaymentElement
          id="payment-element"
          onReady={() => setOnReadyPaymentForm(true)}
          options={{
            layout: {
              type: "tabs",
              defaultCollapsed: false,
            },
          }}
        />
        <DiscountCodeInput />

        <TermsCheckbox>
  <input
    type="checkbox"
    checked={agreedToTerms}
    onChange={() => setAgreedToTerms(!agreedToTerms)}
  />
  <span>
    By checking this box, you agree to the <br className="mobile-break" />
    <a
      href="https://docs.google.com/document/d/1EKrskAZQ7OXWGVdEkZltad9s3XsCV5yXYdSy-lPpt7g/edit?tab=t.0"
      target="_blank"
      rel="noopener noreferrer"
    >
      JackedRabbit Terms and Conditions
    </a>
    .
  </span>
</TermsCheckbox>

        <Button type="submit" disabled={disabled || !agreedToTerms}>
          {isProcessing ? (
            <CircularProgress size={25} />
          ) : (
            effectiveIsTrialSubscription ? "Start Free Month" : COMPLETE_SUBSCRIPTION
          )}
        </Button>
      </Form>
      <CustomModal
        showModal={showStatusModal}
        onClose={handleOpenStatusModal}
        content={
          <StatusScreen
            title={paymentFailure ? WE_COULDNT_PROCCESS_PAYMENT : CHECKOUT_DONE}
            description={
              paymentFailure
                ? PLEASE_CHECK_YOUR_CARD
                : CHECKOUT_DONE_DESCRIPTION
            }
            successButtonTile={GO_TO_DASHBOARD}
            failure={paymentFailure}
            onClose={handleOpenStatusModal}
          />
        }
        backgroundColor={theme.templateColors.lightDark}
        closeColor={theme.templateColors.lightYellow}
      />
    </Container>
  );
};

export default PaymentForm;