import React, { useEffect, useRef, useState } from "react";

import emailjs from "@emailjs/browser";
import { useMediaQuery } from "react-responsive";
import ReCAPTCHA from "react-google-recaptcha";

import EOMButton from "../../../components/EOMButton";
import { ContactUsImage, InstagramBlack, LinkedInBlack } from "../../../assets/images";
import { isTabletOrMobileSize } from "../../../utils/queries";
import {
  CONTACT_US_QUESTION_TITLE,
  CONTACT_US_SUBTITLE,
  CONTACT_US_TITLE,
  JR_EMAIL,
  JR_PHONE,
  MESSAGE_SENT,
  SUBMIT,
} from "../../../utils/strings";
import { publicKey, serviceId, templateId } from "../../../utils/utils";
import {
  Container,
  Title,
  Description,
  SubContainer,
  InnerContainer,
  InnerRightContainer,
  Form,
  TextBox,
  Email,
  Phone,
  Ahref,
  Image,
  CustomizedTextField,
  SocialBox,
} from "./styles";
import { SocialIcon } from "../FooterSection/styles";

const ContactUsSection = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [submission, setSubmission] = useState<string>("");
  const [statusResponse, setStatusResponse] = useState<number>();
  const [recaptcha, setRecaptcha] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);

  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });
  const form = useRef<any>();

  const handleSubbmit = async () => {
    try {
      const response = await emailjs.sendForm(
        serviceId,
        templateId,
        form.current,
        publicKey
      );
      if (response.status === 200) setStatusResponse(response.status);
    } catch (error) {
      console.log("emailJs error: " + error);
    }
  };

  const onChange = (value: any) => {
    setRecaptcha(true);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setStatusResponse(0);
    }, 1500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Container id="contact">
      <Title>{CONTACT_US_TITLE}</Title>
      <SubContainer>
        <InnerContainer>
          <form ref={form} onSubmit={handleSubbmit}>
            <Form>
              <CustomizedTextField
                value={name}
                onChange={(e: any) => {
                  e.preventDefault();
                  setName(e.target.value);
                }}
                label="First and Last Name"
                placeholder="First and Last Name"
                InputLabelProps={{
                  shrink: true,
                }}
                name="from_name"
              />
              <CustomizedTextField
                value={email}
                onChange={(e: any) => {
                  e.preventDefault();
                  setEmail(e.target.value);
                  if (e.target.validity.valid) {
                    setEmailError(false);
                  } else {
                    setEmailError(true);
                  }
                }}
                label="E-mail"
                placeholder="Email"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
                }}
                helperText={emailError ? "Invalid email format" : ""}
                type="email"
                name="from_email"
                FormHelperTextProps={{
                  sx: {
                    color: "red",
                  },
                }}
              />
              <CustomizedTextField
                value={phone}
                onChange={(e: any) => {
                  e.preventDefault();
                  setPhone(e.target.value);
                  if (e.target.validity.valid) {
                    setPhoneError(false);
                  } else {
                    setPhoneError(true);
                  }
                }}
                label="Phone Number"
                placeholder="Phone Number"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  pattern: "^[0-9]+$",
                }}
                helperText={phoneError ? "Invalid phone number" : ""}
                name="from_phone"
                type="tel"
                FormHelperTextProps={{
                  sx: {
                    color: "red",
                  },
                }}
              />
              <CustomizedTextField
                value={submission}
                onChange={(e: any) => {
                  e.preventDefault();
                  setSubmission(e.target.value);
                }}
                label="Submission"
                name="message"
                placeholder="Type Something"
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
              />
            </Form>
          </form>
          {/* <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
              onChange={onChange}
              theme="dark"
              badge="inline"
            /> */}
          <EOMButton
            title={statusResponse === 200 ? MESSAGE_SENT : SUBMIT}
            onPress={handleSubbmit}
            disabled={!name || !email || !phone || !submission}
            buttonStyle={{
              borderRadius: 3,
              width: isTabletOrMobile ? 150 : 200,
              alignSelf: "flex-end",
              marginTop: 30,
            }}
            titleStyle={{
              color: "white",
              fontSize: isTabletOrMobile ? 13 : 16,
              textAlign: "center",
            }}
          />
        </InnerContainer>
        <InnerRightContainer>
          <TextBox>
            <Description>{CONTACT_US_QUESTION_TITLE}</Description>
            <Email>{JR_EMAIL}</Email>
            <SocialBox>
              <Ahref
                href={`https://www.instagram.com/jackedrabbitgyms/`}
                target="_blank"
              >
                <SocialIcon src={InstagramBlack} />
              </Ahref>
              <Ahref
                href={`https://www.linkedin.com/company/jackedrabbit/`}
                target="_blank"
              >
                <SocialIcon src={LinkedInBlack} />
              </Ahref>
            </SocialBox>
          </TextBox>
          <Image src={ContactUsImage} />
        </InnerRightContainer>
      </SubContainer>
    </Container>
  );
};

export default ContactUsSection;
