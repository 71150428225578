import React, { useState, useEffect } from "react";
import { formatDate } from "../../utils/utils";
import { updateMembershipConversionCall } from "../../api/apiOperations";
import { Checkbox, TimestampText } from "./styles";

interface ConversionCheckboxProps {
  membershipId: string;
  isConverted: boolean;
  convertedAt: string | null | undefined;
  isExpired: boolean;
  onUpdate?: () => void; // Original callback for refresh
  onConversionChange?: (membershipId: string, isConverted: boolean, convertedAt: string | null) => void; // New callback
}

const ConversionCheckbox: React.FC<ConversionCheckboxProps> = ({ 
  membershipId, 
  isConverted: initialIsConverted, 
  convertedAt: initialConvertedAt,
  isExpired,
  onUpdate,
  onConversionChange // New callback to update parent state
}) => {
  const [isConverted, setIsConverted] = useState(initialIsConverted);
  const [convertedAt, setConvertedAt] = useState(initialConvertedAt);
  const [isLoading, setIsLoading] = useState(false);

  // Update local state when props change
  useEffect(() => {
    setIsConverted(initialIsConverted);
    setConvertedAt(initialConvertedAt);
  }, [initialIsConverted, initialConvertedAt]);

  const handleToggle = async () => {
    // Uncomment this if you want to prevent expired memberships from being toggled
    // if (isExpired) return;
    
    setIsLoading(true);
    console.log(`Starting conversion toggle for membership ${membershipId} from ${isConverted} to ${!isConverted}`);
    
    try {
      const result = await updateMembershipConversionCall(
        membershipId,
        !isConverted
      );
      
      console.log("API response for conversion:", result);
      
      if (result) {
        const newIsConverted = !isConverted;
        const newConvertedAt = newIsConverted ? new Date().toISOString() : null;
        
        console.log("Updating UI state...");
        setIsConverted(newIsConverted);
        setConvertedAt(newConvertedAt);
        
        // Call the onConversionChange callback to update parent state
        if (onConversionChange) {
          console.log("Calling onConversionChange to update parent state...");
          onConversionChange(membershipId, newIsConverted, newConvertedAt);
        }
        
        // Original callback can still be used for other refresh needs
        if (onUpdate) {
          console.log("Calling onUpdate for additional refresh tasks...");
          onUpdate();
        }
      } else {
        console.log("No result returned from API call");
      }
    } catch (error) {
      console.error("Failed to update conversion status:", error);
    } finally {
      setIsLoading(false);
      console.log("Toggle operation completed");
    }
  };

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '10px',
      minHeight: '24px',
    }}>
      <Checkbox
        type="checkbox"
        checked={isConverted}
        onChange={handleToggle}
        disabled={isLoading}
      />
      <div style={{ width: '70px', textAlign: 'left' }}>
        <TimestampText style={{ visibility: convertedAt ? 'visible' : 'hidden' }}>
          {convertedAt ? formatShortDate(convertedAt) : " "}
        </TimestampText>
      </div>
    </div>
  );  
};

// Helper function to format date as MM/DD/YYYY
const formatShortDate = (dateString: string) => {
  const date = new Date(dateString);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export default ConversionCheckbox;