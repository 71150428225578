import styled from "styled-components";

import theme from "../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f5f5f5;
  width: 95%; /* Match width of other content containers */
  padding: 2% 3% 2% 3%;
  margin: 0 auto; /* Center the container */
  border-radius: 10px; 
  @media (max-width: 1224px) {
    padding: 10% 0% 0% 0%;
    margin-bottom: 9%;
    width: 100%;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  background-color: transparent; /* Remove background color */
  width: 50%;
  justify-content: flex-end;
  padding-top: 1%;
  padding-right: 3%;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2%; /* Align with Account Snapshot title */
  justify-content: flex-end;
  background-color: transparent; /* Remove background color */
  width: 50%;

  @media (max-width: 1224px) {
    padding-left: 8%;
  }
`;

export const Subtitle = styled.span`
  font-size: ${fontSizes.description};
  padding-top: 10px;
  color: #333333; /* Dark grey/black color for text */
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const Title = styled.span`
  font-size: 28px; /* Increased size */
  padding-top: 10px;
  font-weight: 700;
  color: #333333; /* Dark grey/black color for text */
  @media (max-width: 1224px) {
    font-size: ${fontSizes.titleMobile};
  }
`;

export const Image = styled.img`
  height: 60px;
  width: 60px;
  cursor: pointer;
  @media (max-width: 1224px) {
    height: 40px;
    width: 40px;
  }
`;