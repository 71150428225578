import { store } from "../redux/store";
import FormData from "form-data";

import {
  setAllBusiness,
  setBusinessDashboardData,
  setBusinessProfile,
  setIsNextGymSelected,
  setMembershipUpgraded,
  setPartnerGymInfo,
  setUserCanDowngradeToBasic,
  setUserData,
  setUserMembershipStatus,
} from "../slices/appSlice";
import {
  createBusinessProfile,
  getAllBusiness,
  getBusinessDashboardData,
  getBusinessProfile,
  getPartnerGymInfo,
  getStripeConfig,
  getUserMembershipStatus,
  refreshUser,
  subscribeToNextGym,
  updateGallery,
  uploadImage,
  updateMembershipOnboarding,
  updateMembershipConversion,
  validateDiscountCode,
  recordDiscountCodeUsage,
  sendWelcomeEmail,
  cancelSubscriptionImmediately,
  attachPaymentMethodToSubscription,
  deleteMemberSettings
} from "./apiEndpoints";
import { daysUntilExpiration } from "../utils/utils";
import { YOU_CANT_REPEAT_GYM } from "../utils/strings";
import { getAuth } from "firebase/auth";

const dispatch = store.dispatch;

// ------- User  -------

export const getUserCall = async () => {
  try {
    const result = await refreshUser();
    dispatch(setUserData(result?.data.user));
  } catch (error) {
    console.log("getUserCall()", error);
  }
};

export const getUserMembershipStatusCall = async () => {
  try {
    const result = await getUserMembershipStatus();
    dispatch(setUserMembershipStatus(result?.data));
    dispatch(
      setMembershipUpgraded(
        result.data?.settings?.nextProduct?.name === "premium"
      )
    );
    // This is the case when a user has bought a basic membership and has just upgrade for the upcoming month, we want to let the user downgrade to basic only during the month when the upgraded membership has started
    dispatch(
      setUserCanDowngradeToBasic(
        result?.data?.memberships?.active?.product?.name === "premium" &&
          result?.data?.settings?.nextProduct?.name === "premium"
      )
    );
    dispatch(
      setIsNextGymSelected(
        result.data?.settings?.nextGymChoiceMode === "pick" ||
          result.data?.settings?.nextGymChoiceMode === null
      )
    );
  } catch (error) {
    console.log("getUserMembershipStatusCall()", error);
  }
};

// Add this to apiOperations.js
export const sendWelcomeEmailCall = async (email: string, firstName: string, gymName: string) => {
  try {
    const result = await sendWelcomeEmail(email, firstName, gymName);
    console.log("Welcome email sent successfully:", result);
    return "success";
  } catch (error) {
    console.log("sendWelcomeEmailCall error:", error);
    return "error";
  }
};

// ------- Payments  -------

export const getStripeConfigCall = async () => {
  try {
    const result = await getStripeConfig();
    const { publishableKey } = result?.data;
    return publishableKey;
  } catch (error) {
    console.log("getStripeConfigCall", error);
  }
};

export const cancelSubscriptionImmediatelyCall = async (subscriptionId: string) => {
  try {
    const result = await cancelSubscriptionImmediately({ subscriptionId });
    return result?.data;
  } catch (error) {
    console.error("cancelSubscriptionImmediatelyCall error:", error);
    return { success: false };
  }
};


export const attachPaymentMethodToSubscriptionCall = async (
  subscriptionId: string,
  paymentMethodId: string
) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const idToken = await user?.getIdToken();

    if (!idToken) throw new Error("Authorization token missing");

    const result = await attachPaymentMethodToSubscription(
      subscriptionId,
      paymentMethodId,
      idToken
    );

    console.log("✅ Attached payment method to subscription:", result?.data);
    return { success: true };
  } catch (error) {
    console.error("🚨 attachPaymentMethodToSubscriptionCall error:", error);
    return { success: false };
  }
};

export const deleteMemberSettingsCall = async (userId: string) => {
  try {
    const result = await deleteMemberSettings(userId);
    return result?.data;
  } catch (error) {
    console.error("deleteMemberSettingsCall error:", error);
    return { success: false };
  }
};

// ------- Bussines (Gym Owners)  -------

export const getPartnerGymInfoCall = async () => {
  try {
    const result = await getPartnerGymInfo();
    const partnerGymInfo = result?.data?.business;
    dispatch(setPartnerGymInfo(partnerGymInfo));
  } catch (error) {
    console.log("getPartnerGymInfoCall", error);
  }
};

export const getBusinessProfileCall = async (businessId: string) => {
  try {
    const result = await getBusinessProfile(businessId);
    const businessData = result?.data?.business;
    dispatch(setBusinessProfile(businessData));
  } catch (error) {
    console.log("getBusinessProfileCall", error);
  }
};

export const getBusinessDashboardDataCall = async (businessId: string) => {
  try {
    const result = await getBusinessDashboardData(businessId);
    dispatch(setBusinessDashboardData(result?.data));
  } catch (error) {
    console.log("getBusinessDashboardDataCall", error);
  }
};

export const getAllBusinessCall = async () => {
  try {
    const result = await getAllBusiness();
    const businessData = result?.data?.business;
    dispatch(setAllBusiness(businessData));
  } catch (error) {
    console.log("getAllBusinessCall", error);
  }
};

export const subscribeToNextGymCall = async (businessProductId: string) => {
  try {
    const result = await subscribeToNextGym(businessProductId);
    const success = result?.status === 200;
    dispatch(setIsNextGymSelected(success));
    await getUserMembershipStatusCall();
    return result?.status;
  } catch (error: any) {
    console.log("subscribeToNextGymCall", error);
    if (error?.response?.data?.errors?.[0] === YOU_CANT_REPEAT_GYM) {
      return 2;
    } else {
      return 1;
    }
  }
};
export const createBusinessProfileCall = async (data: any) => {
  try {
    const result = await createBusinessProfile(data);
    const profile = result?.data;
    dispatch(setBusinessProfile(profile));
    if (result?.status === 200 || result?.status === 201) {
      await getPartnerGymInfoCall();
      return "success";
    }
  } catch (error) {
    alert(`Create partner profile status:, ${error}`);
    return "error";
  }
};

// Updated API operations to match backend expectations

export interface OnboardingPayload {
  isOnboarded: boolean;
}

export interface ConversionPayload {
  isConverted: boolean;
}

export const updateMembershipOnboardingCall = async (membershipId: string, isOnboarded: boolean) => {
  try {
    // Just send isOnboarded - the backend generates the timestamp
    const payload: OnboardingPayload = { 
      isOnboarded 
    };
    
    console.log("Sending onboarding payload:", payload);
    
    const result = await updateMembershipOnboarding(membershipId, payload);
    return result?.data;
  } catch (error: unknown) {
    console.error("updateMembershipOnboardingCall error:", error);
    
    if (error && typeof error === 'object' && 'response' in error) {
      const errorWithResponse = error as { response: { status: number; data: unknown } };
      console.error("Error response:", {
        status: errorWithResponse.response.status,
        data: errorWithResponse.response.data
      });
    }
    return null;
  }
};

export const updateMembershipConversionCall = async (membershipId: string, isConverted: boolean) => {
  try {
    // Just send isConverted - the backend generates the timestamp
    const payload: ConversionPayload = { 
      isConverted 
    };
    
    // console.log("Sending conversion payload:", payload);
    
    const result = await updateMembershipConversion(membershipId, payload);
    return result?.data;
  } catch (error: unknown) {
    console.error("updateMembershipConversionCall error:", error);
    
    if (error && typeof error === 'object' && 'response' in error) {
      const errorWithResponse = error as { response: { status: number; data: unknown } };
      console.error("Error response:", {
        status: errorWithResponse.response.status,
        data: errorWithResponse.response.data
      });
    }
    return null;
  }
};

export const getSignedGalleryUrlCall = async (
  imageData1: any,
  imageData2: any,
  imageData3: any
) => {
  try {
    const fileData1 = imageData1[0]?.file as any;
    const formData = new FormData();
    formData.append("image", fileData1);

    const fileData2 = imageData2[0]?.file as any;
    const formData2 = new FormData();
    formData2.append("image", fileData2);

    const fileData3 = imageData3[0]?.file as any;
    const formData3 = new FormData();
    formData3.append("image", fileData3);

    const uploadImageResult: any = await Promise.all([
      uploadImage(formData),
      uploadImage(formData2),
      uploadImage(formData3),
    ]);

    if (
      (uploadImageResult?.[0].status === 200 ||
        uploadImageResult?.[0].status === 201) &&
      (uploadImageResult?.[1].status === 200 ||
        uploadImageResult?.[1].status === 201) &&
      (uploadImageResult?.[2].status === 200 ||
        uploadImageResult?.[2].status === 201)
    ) {
      const updateGalleryResult = await updateGallery([
        {
          resourceUri: uploadImageResult?.[0]?.data?.resourceUri,
          contentType: imageData1?.[0]?.file?.type, // mimeTypes
        },
        {
          resourceUri: uploadImageResult?.[1]?.data?.resourceUri,
          contentType: imageData2?.[0]?.file?.type, // mimeTypes
        },
        {
          resourceUri: uploadImageResult?.[2]?.data?.resourceUri,
          contentType: imageData3?.[0]?.file?.type, // mimeTypes
        },
      ]);

      if (
        updateGalleryResult?.status === 200 ||
        updateGalleryResult?.status === 201
      )
        return "success";
    }
  } catch (error) {
    alert(`Gallery update status:, ${error}`);
    return "error";
  }
};

// DISCOUNT CODE FUNCTIONALITY

// Function to validate a discount code
export const validateDiscountCodeCall = async (code: string, subscriptionId?: string) => {
  try {
    const result = await validateDiscountCode(code, subscriptionId);

    if (result?.data?.valid) {
      return {
        valid: true,
        code: result.data.discount.code || "",
        type: result.data.discount.type || "",
        amount: result.data.discount.amount || 0,
        description: result.data.discount.description || "",
        stripePromoId: result.data.discount.stripePromoId || null,
      };
    } else {
      return {
        valid: false,
        message: result?.data?.message || "Invalid discount code",
        code: "",
        type: "",
        amount: 0,
        description: "",
        stripePromoId: null,
      };
    }
  } catch (error) {
    console.error("validateDiscountCodeCall error:", error);
    return {
      valid: false,
      message: "Error validating discount code",
      code: "",
      type: "",
      amount: 0,
      description: "",
      stripePromoId: null,
    };
  }
};

export const recordDiscountCodeUsageCall = async (
  code: string,
  subscriptionId: string,
  email: string
) => {
  try {
    let retries = 3;
    let success = false;
    let error = null;

    while (retries > 0 && !success) {
      try {
        const result = await recordDiscountCodeUsage({ code, subscriptionId, email });

        if (result?.data?.success) {
          console.log("Successfully recorded discount code usage");
          success = true;
          return { success: true };
        } else {
          console.warn("Failed to record discount code usage: API returned success: false");
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      } catch (err) {
        error = err;
        console.error(`Attempt ${4 - retries}/3: Failed to record discount code usage:`, err);
        await new Promise(resolve => setTimeout(resolve, 1000));
      }

      retries--;
    }

    if (!success) {
      console.error("All attempts to record discount code usage failed:", error);
    }

    return { success: false };
  } catch (error) {
    console.error("recordDiscountCodeUsageCall error:", error);
    return { success: false };
  }
};


