// src/components/AccountSnapshot/styles.ts
import styled from "styled-components";
import theme from "../../utils/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;

  @media (max-width: 480px) {
    padding: 0;
    margin: 5px 0 0 0;
  }
`;

export const MetricsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  
  @media (max-width: 1224px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    width: 100%;
    gap: 8px;
    padding: 0;
  }
`;

export const MetricCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e6e6e6; /* Darker gray for better contrast */
  border-radius: 8px;
  padding: 15px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 480px) {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 15px;
    margin: 0 0 8px 0;
    border-radius: 6px;
  }
`;

export const MetricValue = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${theme.templateColors.primaryBlack};
  margin-bottom: 8px;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 28px;
  }
`;

export const MetricLabel = styled.div`
  font-size: 14px;
  color: ${theme.colors.grey};
  text-align: center;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;