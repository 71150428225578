import styled from "styled-components";
import theme from "../../../utils/theme";

const { colors, templateColors } = theme;

interface BasketStyleProps {
  basketproduct: string;
  userdashboard: string;
}
interface LogoProps {
  dashboardscreen: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${templateColors.lightGrey};
  z-index: 600;
  position: fixed;
  width: 100%;
  padding: 0 20px; /* Add padding to the entire container */
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-end; /* Align to the right edge */
  padding: 5px;
  min-width: 180px; /* Reserve space for buttons */
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1; /* Take up available space */
  padding: 5px 5px 5px 8px;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  margin-right: 20px;
  margin-left: 20px; /* Add left margin to the logo container */
`;

export const NavLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1; /* Take remaining space to allow centering */
`;

export const LogoStyle = styled.img<LogoProps>`
  width: 200px;
  height: 70px;
  margin-left: 20px; /* Add left margin to the logo itself */
  object-fit: cover;
  cursor: pointer;
  @media (max-width: 1224px) {
    width: 150px;
    height: 50px;
    margin-left: 8px;
  }
`;

export const NavbarTitle = styled.span`
  font-size: 17px;
  color: ${templateColors.primaryBlack};
  font-family: "Rubik";
  @media (max-width: 1224px) {
    font-size: 12px;
  }
`;

export const BasketStyle = styled.img<BasketStyleProps>`
  width: ${(props) => (props.basketproduct === "true" ? "35px" : "25px")};
  height: ${(props) => (props.basketproduct === "true" ? "35px" : "25px")};
  margin-left: ${(props) => (props.basketproduct === "true" ? "5px" : "15px")};
  margin-right: 25px;
  cursor: pointer;
  z-index: 560;
  @media (max-width: 1224px) {
    margin-right: ${(props) =>
      props.userdashboard === "true" ? "15px" : "60px"};
    margin-top: 5px;
    width: ${(props) => (props.basketproduct === "true" ? "25px" : "18px")};
    height: ${(props) => (props.basketproduct === "true" ? "25px" : "18px")};
  }
`;

export const TitleTab = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 10px;
  height: 15px;
  padding: 10px;
  width: max-content;
  cursor: pointer;
  &:hover {
    background-color: ${templateColors.lightDark};
    & ${NavbarTitle} {
      color: white;
    }
  }
  @media (max-width: 1224px) {
    margin-top: 8px;
    width: 100px;

    ${NavbarTitle} {
      color: white;
    }
  }
`;

export const LoginButton = styled.div`
  padding: 10px 20px;
  background-color: ${templateColors.lightDark};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px;
  font-size: 17px;
  color: ${colors.white};
  font-family: "Rubik";
  // &:hover {
  //   background-color: ${templateColors.lightDark};
  //   color: white;
  // }
  @media (max-width: 1224px) {
    font-size: 12px;
    margin: 10px; /* Reduce margins */
    padding: 8px 15px; /* Slightly smaller padding */
    width: calc(100% - 20px); /* Set a width that accounts for margins */
    text-align: center; /* Center the text */
  }
`;