import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Import icons but handle the TypeScript errors
import { FaTimes, FaChevronLeft, FaChevronRight } from "react-icons/fa";

import GymCard from "../../../components/GymCard";
import { sortGymsByCustomOrder } from "../../../utils/utils";
import {
  Container,
  TopContainer,
  Title,
  ScrollableContainer,
  BottomContainer,
  ModalOverlay,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  CloseButton,
  ModalBody,
  ModalGallery,
  GalleryImage,
  GalleryNavButton,
  ModalSection,
  SectionTitle,
  ModalContent,
  FeaturesList,
  FeatureItem,
  LocationInfo,
  PriceInfo,
  SelectButton,
  SoldOutButton,
  ModalFooter
} from "./styles";
import AmenitiesIconList from "../../../components/AmenitiesIconList";
import { Business } from "../../../utils/types";
import { addBasketProduct, setSelectedProduct } from "../../../slices/appSlice";
import { CHECKOUT } from "../../../utils/routes";

// Define RootState interface for TypeScript
interface RootState {
  appData: {
    allBusiness: Business[];
    gymOwnerLoggedIn: boolean;
  },
  auth: {
    memberLoggedIn: boolean;
  }
}

// Array of gym names that are sold out
const SOLD_OUT_GYMS = ["AIRLAB Fitness", "CKO EDGEWATER", "CrossFit Wynwood"];

const GymHopSection: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { allBusiness } = useSelector((state: RootState) => state.appData);
  const { gymOwnerLoggedIn } = useSelector((state: RootState) => state.appData);
  const { memberLoggedIn } = useSelector((state: RootState) => state.auth);
  
  const customOrdererArr = sortGymsByCustomOrder(
    allBusiness,
    "SWEAT440 Midtown Miami"
  );

  const [selectedGym, setSelectedGym] = useState<Business | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  const handleGymClick = (gym: Business): void => {
    setSelectedGym(gym);
    setCurrentImageIndex(0);
    setShowModal(true);
  };

  const closeModal = (): void => {
    setShowModal(false);
    setSelectedGym(null);
  };
  
  const nextImage = () => {
    if (selectedGym?.gallery && Array.isArray(selectedGym.gallery) && selectedGym.gallery.length > 0) {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === selectedGym.gallery.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const prevImage = () => {
    if (selectedGym?.gallery && Array.isArray(selectedGym.gallery) && selectedGym.gallery.length > 0) {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === 0 ? selectedGym.gallery.length - 1 : prevIndex - 1
      );
    }
  };
  
  // Helper function to get image URL from gallery item
  const getImageUrl = (item: any): string => {
    if (!item) return "";
    
    // If it's directly a string, return it
    if (typeof item === 'string') return item;
    
    // If it's an object with specific properties
    if (item && typeof item === 'object') {
      // Try different possible properties
      if ('imageUrl' in item && typeof item.imageUrl === 'string') 
        return item.imageUrl;
      
      if ('resourceUrl' in item && typeof item.resourceUrl === 'string') 
        return item.resourceUrl;
      
      if ('url' in item && typeof item.url === 'string') 
        return item.url;
      
      if ('src' in item && typeof item.src === 'string') 
        return item.src;
    }
    
    return "";
  };

  // Check if a gym is sold out
  const isGymSoldOut = (gymName: string): boolean => {
    return SOLD_OUT_GYMS.includes(gymName);
  };
  
  const handleSelectGym = () => {
    if (!selectedGym) return;
    
    // Don't proceed if the gym is sold out
    if (isGymSoldOut(selectedGym.name)) {
      closeModal();
      return;
    }
    
    const basicMembershipProduct = selectedGym.products?.find(p => p.name?.toLowerCase() === 'basic');
    
    if (basicMembershipProduct) {
      dispatch(addBasketProduct(selectedGym));
      dispatch(setSelectedProduct(basicMembershipProduct));
      navigate(gymOwnerLoggedIn ? "/dashboard/gym_owners" : CHECKOUT);
    }
    
    closeModal();
  };

  return (
    <Container id="our-facilities">
      <TopContainer>
        <Title>Our Facilities</Title>
      </TopContainer>
      
      <ScrollableContainer>
        <BottomContainer>
          {customOrdererArr?.map((gymCard: Business) => (
            <GymCard 
              key={gymCard?.businessId} 
              gymCard={gymCard} 
              onClick={() => handleGymClick(gymCard)}
            />
          ))}
        </BottomContainer>
      </ScrollableContainer>

      {showModal && selectedGym && (
        <ModalOverlay onClick={closeModal}>
          <ModalContainer onClick={(e) => e.stopPropagation()}>
            <ModalHeader>
              <ModalTitle>{selectedGym.name}</ModalTitle>
              <CloseButton onClick={closeModal}>
                <span style={{ display: 'flex' }}>
                  {/* @ts-ignore */}
                  <FaTimes size={20} />
                </span>
              </CloseButton>
            </ModalHeader>
            
            <ModalBody>
              <ModalGallery>
                {selectedGym.gallery && Array.isArray(selectedGym.gallery) && selectedGym.gallery.length > 0 && (
                  <GalleryImage 
                    src={getImageUrl(selectedGym.gallery[currentImageIndex])} 
                    alt={`${selectedGym.name || ""} image`} 
                  />
                )}
                
                {selectedGym.gallery && Array.isArray(selectedGym.gallery) && selectedGym.gallery.length > 1 && (
                  <>
                    <GalleryNavButton left onClick={prevImage}>
                      {/* @ts-ignore */}
                      <span style={{ display: 'flex' }}><FaChevronLeft size={18} /></span>
                    </GalleryNavButton>
                    
                    <GalleryNavButton right onClick={nextImage}>
                      {/* @ts-ignore */}
                      <span style={{ display: 'flex' }}><FaChevronRight size={18} /></span>
                    </GalleryNavButton>
                  </>
                )}
              </ModalGallery>
              
              <ModalContent>
                <ModalSection>
                  <SectionTitle>About {selectedGym.name}</SectionTitle>
                  <p>{selectedGym.description || `Experience premium fitness at ${selectedGym.name}. State-of-the-art equipment and expert trainers await you.`}</p>
                </ModalSection>
                
                <ModalSection>
                  <SectionTitle>Features</SectionTitle>
                  <FeaturesList>
                    {selectedGym.amenities && selectedGym.amenities.map((feature, index) => (
                      <FeatureItem key={index}>{feature}</FeatureItem>
                    ))}
                  </FeaturesList>
                </ModalSection>
                
                <ModalSection>
                  <SectionTitle>Location</SectionTitle>
                  <LocationInfo>{selectedGym.address?.fullAddress}</LocationInfo>
                </ModalSection>
              </ModalContent>
            </ModalBody>
            
            <ModalFooter>
              <PriceInfo>$75/month</PriceInfo>
              
              {isGymSoldOut(selectedGym.name) ? (
                <SoldOutButton disabled>Sold Out</SoldOutButton>
              ) : (
                <SelectButton onClick={handleSelectGym}>
                  Select This Facility
                </SelectButton>
              )}
            </ModalFooter>
          </ModalContainer>
        </ModalOverlay>
      )}
    </Container>
  );
};

export default GymHopSection;