import React from "react";
import { v4 as uuidv4 } from "uuid";
// Remove the problematic import

import {
  BigThreeIconY,
  ColdPlungeIconY,
  GroupClasessIconY,
  LockersIconY,
  NutritionBarIconY,
  PersonalTrainingY,
  RedLightIconY,
  SaunaIconY,
  ShowersIconY,
  SpinningIconY,
  SteamRoomIconY,
  SwimmingPoolIconY,
  TanningIconY,
  TwentyFourOpenY,
} from "../../assets/images";
import { Image, Container, SubContainer, Tile, BoxContainer } from "./styles";

interface AmenitiesIconProps {
  amenities: string[];
}

// Create a simple checkmark component that's guaranteed to work
const CheckIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="16" 
    height="16" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
    style={style}
  >
    <polyline points="20 6 9 17 4 12"></polyline>
  </svg>
);

const iconMapping: Record<string, string> = {
  "Big 3": BigThreeIconY,
  "Cold Plunge": ColdPlungeIconY,
  "Group classes": GroupClasessIconY,
  Lockers: LockersIconY,
  "Nutrition Bar": NutritionBarIconY,
  "Personal training": PersonalTrainingY,
  "Red Light Therapy": RedLightIconY,
  Sauna: SaunaIconY,
  Showers: ShowersIconY,
  Spinning: SpinningIconY,
  "Steam Room": SteamRoomIconY,
  "Swimming pool": SwimmingPoolIconY,
  Tanning: TanningIconY,
  "24/7 Access": TwentyFourOpenY,
};

const AmenitiesIconList = ({ amenities }: AmenitiesIconProps) => {
  return (
    <Container>
      {amenities?.map((amenity) => {
        const iconSrc = iconMapping[amenity];
        return iconSrc ? (
          <BoxContainer key={uuidv4()}>
            <CheckIcon style={{ color: "white" }} />
            <Tile key={uuidv4()}>{amenity}</Tile>
          </BoxContainer>
        ) : null;
      })}
    </Container>
  );
};

export default AmenitiesIconList;