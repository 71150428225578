import React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import theme from "../../utils/theme";

const { colors } = theme;

interface EOMButtonProps {
  title: string;
  onPress?: any;
  loading?: boolean;
  outlined?: boolean;
  buttonStyle?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  borderRadius?: number;
  borderColor?: string;
  disabled?: boolean;
  secondTitle?: string;
  icon?: string;
}

const EOMButton: React.FC<EOMButtonProps> = ({
  title,
  onPress,
  disabled,
  loading = false,
  outlined = false,
  buttonStyle,
  titleStyle,
  borderRadius = 16,
  borderColor = theme.templateColors.lightYellow,
  secondTitle,
  icon,
  ...props
}: EOMButtonProps) => {
  const setBackgroundColor = () => {
    if (outlined) {
      return "transparent";
    }
    if (disabled) {
      return theme.templateColors.lightDark;
    } else {
      return theme.templateColors.lightDark;
    }
  };

  return (
    <div
      style={{
        cursor: "pointer",
        padding: 10,
        borderRadius: borderRadius,
        alignItems: "center",
        justifyContent: "center",
        borderWidth: outlined ? 1.2 : 0,
        borderColor: theme.templateColors.lightYellow,
        backgroundColor: setBackgroundColor(),
        ...buttonStyle,
      }}
      onClick={loading || disabled ? undefined : onPress}
      {...props}
      key={title}
    >
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={20} />
        </Box>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {icon && (
            <img src={icon} alt="Icon" style={{ marginRight: 20, width: 15 }} />
          )}
          <div
            style={{
              color: outlined ? colors.blue : "white",
              textAlign: "center",

              ...titleStyle,
            }}
          >
            {title}
          </div>
          {!!secondTitle && (
            <div style={{ color: outlined ? colors.blue : "white" }}>
              {secondTitle}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EOMButton;
