import styled from "styled-components";
import theme from "../../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

interface ButtonProps {
  disabled?: boolean;
}

export const Container = styled.div``;

export const Form = styled.form``;

export const Tile = styled.span`
  color: ${theme.colors.white};
  font-size: ${fontSizes.description};
  font-weight: 400;
`;

export const Button = styled.button<ButtonProps>`
  background: ${(props) =>
    props.disabled
      ? theme.templateColors.lightDark
      : theme.templateColors.lightDark};

  border: none;
  width: 100%;
  height: 39px;
  margin-top: 30px;
  border-radius: 3px;
  color: ${theme.colors.white};
  font-size: ${fontSizes.subtitle};
  font-weight: 600;
  text-align: "center";
  cursor: pointer;

  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
  }
`;

export const TermsCheckbox = styled.label`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  margin: 1rem 0;
  line-height: 1.4;

  input {
    margin-right: 8px;
    margin-top: 2px;
  }

  a {
    color: #006BFF;
    text-decoration: underline;
  }

  .mobile-break {
    display: none;
  }

  @media (max-width: 480px) {
    flex-direction: row;
    align-items: flex-start;

    .mobile-break {
      display: block;
    }
  }
`;

