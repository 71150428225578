import React, { useState } from "react";

import { Turn as Hamburger } from "hamburger-react";
import { HashLink as Link } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import CustomModal from "../CustomModal";
import { navbarTiles } from "../../utils/arrays";
import Login from "../../features/auth/Login";
import theme from "../../utils/theme";
import { DASHBOARD, LOGIN } from "../../utils/strings";
import { DASHBOARD_GYM_OWNERS, DASHBOARD_MEMBERS } from "../../utils/routes";
import {
  LoginButton,
  NavbarTitle,
  TitleTab,
} from "../../features/app/Navbar/styles";
import { Container, SubContainer } from "./styles";

interface BurgerMenuPros {
  size: number;
  color: string;
}

const BurgerMenu = ({ size, color }: BurgerMenuPros) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const { loggedIn, gymOwnerLoggedIn, memberLoggedIn } = useSelector(
    (state: RootState) => state.auth
  );

  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(!isOpen);
  };

  const handleOpenDashboard = () => {
    if (gymOwnerLoggedIn) navigate(DASHBOARD_GYM_OWNERS);
    if (memberLoggedIn) navigate(DASHBOARD_MEMBERS);
  };

  const handleOpenModal = () => {
    setShowModal(!showModal);
  };

  return (
    <Container>
      <Hamburger toggled={isOpen} toggle={setOpen} size={size} color={color} />
      {isOpen && (
        <SubContainer>
          {navbarTiles.map(({ id, name }) => (
            <Link
              to={id}
              smooth
              style={{ textDecoration: "none" }}
              onClick={handleOpen}
              key={id}
            >
              <TitleTab>
                <NavbarTitle>{name}</NavbarTitle>
              </TitleTab>
            </Link>
          ))}
          <LoginButton
            onClick={loggedIn ? handleOpenDashboard : handleOpenModal}
          >
            {loggedIn ? DASHBOARD : LOGIN}
          </LoginButton>
        </SubContainer>
      )}
      <CustomModal
        showModal={showModal}
        onClose={handleOpenModal}
        content={<Login />}
        backgroundColor={theme.templateColors.lightDark}
        closeColor={theme.templateColors.lightYellow}
      />
    </Container>
  );
};

export default BurgerMenu;
