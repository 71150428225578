import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MapPin, CalendarCheck, ArrowRight, ChevronLeft, ChevronRight } from "lucide-react"; // You'll need to install lucide-react

import { ArrowRightYellow } from "../../assets/images";
import { setSelectedProduct } from "../../slices/appSlice";
import { filterByName } from "../../utils/utils";
import { upgradeToPremium } from "../../api/apiEndpoints";
import CustomModal from "../CustomModal";
import StatusScreen from "../StatusScreen";
import {
  CONFIRM,
  MEMBERSHIP_TYPE,
  MEMBERSHIP_UPGRADED,
  NEXT_GYM,
  NO_UPGRADE_MODE_TILE,
  PLEASE_SELECT_YOUR_NEXT,
  SELECTION_PENDING,
  WE_COULDNT_PROCCESS_PAYMENT,
  YOUR_ARE_ABOUT_TO_UPGRADE,
} from "../../utils/strings";
import theme from "../../utils/theme";
import { getUserMembershipStatusCall } from "../../api/apiOperations";
import {
  Container,
  CardImageContainer,
  GymImage,
  ContentContainer,
  GymName,
  AddressContainer,
  MembershipRow,
  Label,
  Value,
  ExpirationRow,
  ExpirationHeader,
  ExpirationValue,
  NextGymRow,
  StatusValue,
  SelectionMessage,
  ActionButton,
  ButtonText,
  FeatureTag,
  FeaturesContainer,
  GalleryNavButton,
  DetailsSection,
  ActionsSection,
  InfoRow
} from "./styles"; // We'll update the styles file

interface InfoCardProps {
  tileOne: string;
  subtile: string;
  title: string;
  description: string;
  descriptionTwo?: string;
  descriptionThree?: string;
  bottomTile: string;
  upgradeMembershipPrice: number;
}

const InfoCard = ({
  tileOne,
  subtile,
  title,
  description,
  descriptionTwo,
  descriptionThree,
  bottomTile,
  upgradeMembershipPrice,
}: InfoCardProps) => {
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [paymentFailure, setPaymentFailure] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false);
  const [showSummaryBox, setShowSummaryBox] = useState<boolean>(true);

  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  const dispatch = useDispatch();

  const {
    selectedProduct,
    businessProfile,
    isNextGymSelected,
    userMembershipStatus,
    membershipUpgraded,
  } = useSelector((state: RootState) => state.appData);
  const { idToken } = useSelector((state: RootState) => state.auth);

  const upgradeMembershipAmount = filterByName(
    businessProfile?.products,
    "premium"
  );

  const { nextBusinessName } = userMembershipStatus?.settings || {};

  // Check if this gym doesn't support upgrades
  const noUpgradeModeGymByName =
    tileOne === "Elev8tion Fitness" ||
    tileOne === "UFC GYM Midtown " ||
    tileOne === "AIRLAB Fitness" ||
    tileOne === "Omega Wellness Club" ||
    tileOne === "Gotham Gym Miami";

  const notFailureTitle = paymentSuccess
    ? MEMBERSHIP_UPGRADED
    : YOUR_ARE_ABOUT_TO_UPGRADE;
  const paymentInfo = {
    upgradeMembershipPrice: upgradeMembershipPrice?.toFixed(2),
    taxAmount: upgradeMembershipAmount?.price?.taxAmount,
    feeAmount: upgradeMembershipAmount?.price?.feeAmount,
    total: upgradeMembershipAmount?.price?.total,
    preTaxCost: upgradeMembershipAmount?.price?.preTaxCost,
  };

  const handleUpgradeButton = () => {
    if (!membershipUpgraded) {
      setShowStatusModal(true);
      dispatch(setSelectedProduct(upgradeMembershipAmount));
    }
  };

  const handleUpgradeMembership = async () => {
    setLoading(true);
    if (idToken && selectedProduct) {
      const result = await upgradeToPremium(selectedProduct?.businessProductId);

      if (result.status === 200) {
        setLoading(false);
        setPaymentSuccess(true);
        setShowSummaryBox(false);
        await getUserMembershipStatusCall();
      } else {
        setLoading(false);
        setPaymentFailure(true);
        setShowSummaryBox(false);
      }
    }
  };

  const handleOpenStatusModal = () => {
    if (paymentSuccess) {
      setPaymentSuccess(false);
    }
    setShowStatusModal(!showStatusModal);
  };

  // Sample features for the gym - replace with actual features from API if available
  const gymFeatures = businessProfile?.features || 
                      businessProfile?.amenities || 
                      ["Cold Plunge", "Group classes", "Nutrition Bar"];

  // Get the right membership type display text
  const membershipType = descriptionTwo === "basic" ? "Hopper" : "Power";

  // Get next gym status message
  const nextGymStatus = !isNextGymSelected && !membershipUpgraded 
    ? SELECTION_PENDING
    : (isNextGymSelected && !membershipUpgraded 
      ? nextBusinessName 
      : `${tileOne} (Upgraded to Power)`);

  // Handle image gallery
  const gallery = businessProfile?.gallery || [];
  const hasMultipleImages = Array.isArray(gallery) && gallery.length > 1;
  
  // Get image URL from gallery item - similar to GymCard implementation
  const getImageUrl = (item: any): string => {
    if (!item) return "";
    
    // If it's directly a string, return it
    if (typeof item === 'string') return item;
    
    // If it's an object with specific properties
    if (item && typeof item === 'object') {
      // Try different possible properties
      if ('imageUrl' in item && typeof item.imageUrl === 'string') 
        return item.imageUrl;
      
      if ('resourceUrl' in item && typeof item.resourceUrl === 'string') 
        return item.resourceUrl;
      
      if ('url' in item && typeof item.url === 'string') 
        return item.url;
      
      if ('src' in item && typeof item.src === 'string') 
        return item.src;
    }
    
    // Fallback to placeholder
    return `https://via.placeholder.com/400x240?text=${encodeURIComponent(tileOne || 'Gym')}`;
  };
  
  // Get current image from gallery
  const currentImage = gallery && Array.isArray(gallery) && gallery.length > 0 
    ? getImageUrl(gallery[currentImageIndex]) 
    : `https://via.placeholder.com/400x240?text=${encodeURIComponent(tileOne || 'Gym')}`;
  
  // Handle gallery navigation
  const nextImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (Array.isArray(gallery) && gallery.length > 0) {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === gallery.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const prevImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (Array.isArray(gallery) && gallery.length > 0) {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === 0 ? gallery.length - 1 : prevIndex - 1
      );
    }
  };

  // Get gym address properly
  const gymAddress = businessProfile?.address 
    ? (typeof businessProfile.address === 'string' 
        ? businessProfile.address 
        : businessProfile.address.fullAddress || "Address unavailable")
    : "2243 N Miami Ave #110, Miami, FL 33127";

  return (
    <Container>
      {/* Gym Image */}
      <CardImageContainer>
        <GymImage src={currentImage} alt={tileOne} />
        
        {hasMultipleImages && (
          <>
            <GalleryNavButton 
              left="true"
              onClick={prevImage}
              aria-label="Previous image"
            >
              <ChevronLeft size={16} />
            </GalleryNavButton>
            
            <GalleryNavButton 
              right="true"
              onClick={nextImage}
              aria-label="Next image"
            >
              <ChevronRight size={16} />
            </GalleryNavButton>
          </>
        )}
      </CardImageContainer>
      
      {/* Card Content */}
      <ContentContainer>
        <DetailsSection>
          <GymName>{tileOne}</GymName>
          
          {/* Address */}
          <AddressContainer>
            <MapPin size={16} style={{ marginRight: "6px" }} />
            <span>{typeof gymAddress === 'object' ? 
              (gymAddress.fullAddress || "Address unavailable") : 
              gymAddress}
            </span>
          </AddressContainer>
          
          <InfoRow>
            <div>
              {/* Membership Type */}
              <MembershipRow>
                <Label>{`${MEMBERSHIP_TYPE}:`}</Label>
                <Value>{membershipType}</Value>
              </MembershipRow>
              
              {/* Expiration */}
              <ExpirationRow>
                <ExpirationHeader>
                  <CalendarCheck size={16} style={{ marginRight: "6px" }} />
                  <Label>{description}</Label>
                </ExpirationHeader>
                <ExpirationValue>{descriptionThree}</ExpirationValue>
              </ExpirationRow>
            </div>
            
            <div>
              {/* Next Gym */}
              <NextGymRow>
                <Label>{NEXT_GYM}</Label>
                <StatusValue
                  style={{
                    fontStyle: isNextGymSelected ? "normal" : "italic",
                  }}
                >
                  {nextGymStatus}
                </StatusValue>
              </NextGymRow>
              
              {/* Selection message */}
              {!isNextGymSelected && !membershipUpgraded && (
                <SelectionMessage>{PLEASE_SELECT_YOUR_NEXT}</SelectionMessage>
              )}
            </div>
          </InfoRow>
          
          {/* Features */}
          <FeaturesContainer>
            {gymFeatures.map((feature: string, index: number) => (
              <FeatureTag key={index}>{feature}</FeatureTag>
            ))}
          </FeaturesContainer>
        </DetailsSection>
        
        <ActionsSection>
          {/* Action Button */}
          {noUpgradeModeGymByName ? (
            <ActionButton disabled>
              <ButtonText>{NO_UPGRADE_MODE_TILE}</ButtonText>
            </ActionButton>
          ) : (
            <ActionButton>
              <ButtonText>{bottomTile}</ButtonText>
              {/* {!membershipUpgraded && <ArrowRight size={16} style={{ marginLeft: "8px" }} />} */}
            </ActionButton>
          )}
        </ActionsSection>
      </ContentContainer>
      
      {/* Modal for upgrade confirmation */}
      <CustomModal
        showModal={showStatusModal}
        onClose={handleOpenStatusModal}
        content={
          <StatusScreen
            showIcon={false}
            title={
              paymentFailure ? WE_COULDNT_PROCCESS_PAYMENT : notFailureTitle
            }
            showButton={paymentSuccess ? false : true}
            successButtonTile={CONFIRM}
            failure={paymentFailure}
            loading={loading}
            onClose={handleOpenStatusModal}
            onButtonClick={handleUpgradeMembership}
            useButtonEvent={paymentSuccess ? false : true}
            info={paymentInfo}
            showSummary={showSummaryBox}
          />
        }
        backgroundColor={theme.templateColors.lightDark}
        closeColor={theme.templateColors.lightYellow}
      />
    </Container>
  );
};

export default InfoCard;