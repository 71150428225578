import styled from "styled-components";
import theme from "../../../utils/theme";

const { fontSizes, templateColors } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${templateColors.lightGrey};
  min-height: 60vh; // Changed from fixed height to relative
  padding-top: 5rem; // Add padding for navbar
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  position: relative;
  overflow: hidden;
  
  @media (max-width: 1224px) {
    min-height: 50vh; // Shorter on mobile
    padding-top: 4rem;
  }
`;

export const SubtitleBox = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  padding-left: 13%;
  
  @media (max-width: 1224px) {
    margin-bottom: 2%;
    padding-left: 0;
    width: 100%;
    gap: 12px;
  }
`;

export const VideoDiv = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
  margin-bottom: 51%;
  pointer-events: "none";
  @media (max-width: 1224px) {
    margin-bottom: 100%;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 500;
  opacity: 0;
  margin-top: 0; // Removed the 12% margin-top

  @media (max-width: 933px) {
    height: 100%;
  }
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
`;

export const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 52%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%); // Centered with slight upward shift
  z-index: 550;
  align-items: center;
  font-family: "Inter";

  @media (max-width: 1224px) {
    width: 90%;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    margin: 4rem auto 2rem auto; // Changed from px to rem
    align-items: center;
    padding: 0 1.25rem;
  }
`;

export const Title = styled.span`
  color: black;
  align-self: "center";
  font-size: ${fontSizes.bigTitle};
  font-weight: 800;
  text-align: center;
  z-index: 550;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.bigTitleMobile};
    margin-bottom: 5%;
    text-align: center;
    padding-left: 0;
    line-height: 41px;
  }
`;

export const SubTitle = styled.span`
  color: black;
  align-self: "flex-start";
  text-align: center;
  font-size: 22px;
  font-size: ${fontSizes.title};
  line-height: 30px;
  margin-top: 10px;
  z-index: 550;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.titleMobile};
    line-height: 22px;
    font-weight: 400;
  }
`;

export const SubTitleBullet = styled.span`
  color: black;
  align-self: "flex-start";
  text-align: left;
  font-size: 25px;
  font-size: ${fontSizes.title};
  line-height: 30px;
  z-index: 550;
  @media (max-width: 1224px) {
    display: none; /* Hide the original bullet points on mobile */
  }
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  
  @media (max-width: 1224px) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ButtonWrapper = styled.div`
  position: relative;
  z-index: 600;
  margin-top: 1.5rem; // Changed from px to rem
  display: flex;
  justify-content: center;
  width: 100%;
  
  @media (max-width: 1224px) {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    margin-bottom: 1.875rem; // Changed from px to rem
  }
`;

// New mobile-specific components
export const BenefitCardsContainer = styled.div`
  display: none; /* Hidden on desktop */
  
  @media (max-width: 1224px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const BenefitCard = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  
  &:hover {
    transform: translateY(-3px);
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${templateColors.lightDark};
  margin-right: 16px;
  flex-shrink: 0;
`;

export const BenefitText = styled.span`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #333;
`;

// NEW HERO TO IMPLEMENT WHEN FIGMA DESIGN IS DONE

// import styled from "styled-components";

// export const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   background-color: #f6f5f3;
//   min-height: 100vh;
//   width: 100vw;
//   margin-left: calc(-50vw + 50%);
//   position: relative;
//   overflow: hidden;
// `;

// export const CollageBackground = styled.img`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   z-index: 0;
// `;

// export const ContentCard = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   background-color: #ffffff;
//   border-radius: 40px;
//   padding: 32px;
//   z-index: 1;
//   max-width: 440px;
//   width: 90%;
//   text-align: center;
  
//   @media (max-width: 768px) {
//     padding: 24px;
//     width: 85%;
//   }
// `;

// export const Logo = styled.img`
//   height: auto;
//   width: 180px; 
//   margin-bottom: 0px;
  
//   @media (max-width: 768px) {
//     width: 160px;
//   }
// `;

// export const SubTitle = styled.p`
//   color: #232532;
//   text-align: center;
//   font-family: 'Montserrat', sans-serif;
//   font-weight: 300; // Light
//   font-size: 23px;
//   line-height: 1.4;
//   margin: 0 0 24px;
  
//   @media (min-width: 769px) {
//     font-size: 22px; // Slightly smaller to match image
//   }
// `;

// export const ButtonWrapper = styled.div`
//   width: 100%;
//   margin-top: 4px;
// `;

// export const Button = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #dfec49;
//   border-radius: 100px;
//   width: 100%;
//   padding: 12px 0; // Smaller padding to match image
//   cursor: pointer;
//   transition: all 0.2s ease;
  
//   &:hover {
//     transform: translateY(-2px);
//     box-shadow: 0 4px 12px rgba(223, 236, 73, 0.3);
//   }
// `;

// export const ButtonText = styled.span`
//   color: #232532;
//   font-family: 'Montserrat', sans-serif;
//   font-weight: 500; // Medium
//   font-size: 20px; // Smaller font size to match image
  
//   @media (max-width: 768px) {
//     font-size: 18px;
//   }
// `;