import styled from "styled-components";

import theme from "../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

interface TileStatusProps {
  accountStatus: string;
}
interface TileTypeProps {
  membershipType?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-content: center;
  align-items: center;
  background-color: ${templateColors.primaryBlack};
  width: 95%;
  @media (max-width: 1224px) {
    width: 95%;
  }
`;

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  background-color: ${templateColors.backgroundWhite};
  width: 95%;
  border-radius: 10px;
  padding: 3%;
  @media (max-width: 1224px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${templateColors.backgroundWhite};
  @media (max-width: 1224px) {
  }
`;

export const Subcontainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  width: 100%;
  border-radius: 10px;
  margin-top: 40px;
  padding: 2%;
  @media (max-width: 1224px) {
    width: 100%;
    padding: 1%;
    margin-top: 30px;
  }
`;

export const ScrollBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  height: 400px;
  overflow-y: scroll;
  width: 100%;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  
  @media (max-width: 768px) {
    height: auto;
    max-height: 600px;
  }
`;

// Grid-based row layout - updated to remove ACCOUNT_STATUS column
export const Row = styled.div<RowProps>`
  display: grid;
  grid-template-columns: 12% 20% 12% 14% 12% 15% 15%;
  background-color: ${colors.white};
  width: 100%;
  
  @media (max-width: 768px) {
    display: ${props => props.isHeader ? 'none' : 'flex'};
    flex-direction: column;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    
    &:last-child {
      border-bottom: none;
    }
  }
`;

// Basic cell without fixed width
export const Cell = styled.div<CellProps>`
  display: flex;
  background-color: ${colors.white};
  padding: 0 10px 0 0;
  
  @media (max-width: 768px) {
    padding: 8px 5px;
    display: flex;
    align-items: center;
    
    &:before {
      content: attr(data-label);
      font-weight: bold;
      width: 40%;
      margin-right: 10px;
    }
  }
`;

export const Title = styled.span`
  font-size: ${fontSizes.title};
  font-weight: 600;

  @media (max-width: 1224px) {
    font-size: ${fontSizes.titleMobile};
  }
`;

export const Tile = styled.span`
  font-size: ${fontSizes.description};
  font-weight: 400;
  text-align: start;
  margin-top: 16px;
  margin-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: ${fontSizes.descriptionMobile};
    white-space: normal;
    word-break: break-word;
    margin-top: 0;
    margin-bottom: 0;
    flex: 1;
  }
`;

export const TileTwo = styled.span`
  font-size: ${fontSizes.subtitleMobile};
  font-weight: 600;
  text-align: start;
  margin-bottom: 10px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const TileTree = styled.span`
  font-size: ${fontSizes.description};
  font-weight: 400;
  text-align: start;
  margin-bottom: 10px;
  margin-top: 10px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const TileStatus = styled.span<TileStatusProps>`
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: ${fontSizes.description};
  font-weight: 400;
  text-align: center;
  width: 95px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 100px;
  height: 32px;
  color: ${colors.white};
  background-color: ${(props) =>
    props.accountStatus === "true" ? colors.red : colors.green};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.xsMobile};
    width: 55px;
    height: 25px;
  }
`;

export const TileType = styled.span<TileTypeProps>`
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: ${fontSizes.description};
  font-weight: 500;
  text-align: center;
  width: 95px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 100px;
  height: 32px;
  background-color: ${(props) =>
    props.membershipType === "basic"
      ? templateColors.primaryGrey
      : templateColors.lightViolet};
  
  @media (max-width: 768px) {
    font-size: ${fontSizes.xsMobile};
    width: auto;
    min-width: 80px;
    height: 25px;
    margin: 0;
  }
`;

// Interface for ExpirationStatus props
interface ExpirationStatusProps {
  daysLeft: number | null;
}

interface RowProps {
  isHeader?: boolean;
}

interface CellProps {
  'data-label'?: string;
}

// Component for membership expiration status bubbles
export const ExpirationStatus = styled.span<ExpirationStatusProps>`
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: ${fontSizes.description};
  font-weight: 400;
  text-align: center;
  width: 95px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 100px;
  height: 32px;
  color: ${colors.white};
  background-color: ${(props) => {
    if (props.daysLeft === null) return '#A9A9A9'; // Gray for expired/canceled
    const days = props.daysLeft;
    if (Number.isNaN(days)) return colors.green; // Fallback if parsing fails
    return days > 10 ? colors.green : '#F9A826'; // Green for >10 days, Yellow for ≤10 days
  }};
  @media (max-width: 768px) {
    font-size: ${fontSizes.xsMobile};
    width: auto;
    min-width: 80px;
    height: 25px;
    margin: 0;
  }
`;