import React, { useState, useEffect } from "react";
import { formatDate } from "../../utils/utils";
import { updateMembershipOnboardingCall } from "../../api/apiOperations";
import { Checkbox, TimestampText } from "./styles";

interface OnboardingCheckboxProps {
  membershipId: string;
  isOnboarded: boolean;
  onboardedAt: string | null | undefined;
  isExpired: boolean;
  onUpdate?: () => void; // New prop for refresh callback
}

const OnboardingCheckbox: React.FC<OnboardingCheckboxProps> = ({ 
  membershipId, 
  isOnboarded: initialIsOnboarded, 
  onboardedAt: initialOnboardedAt,
  isExpired,
  onUpdate // New callback to refresh data
}) => {
  const [isOnboarded, setIsOnboarded] = useState(initialIsOnboarded);
  const [onboardedAt, setOnboardedAt] = useState(initialOnboardedAt);
  const [isLoading, setIsLoading] = useState(false);

  // Update local state when props change
  useEffect(() => {
    setIsOnboarded(initialIsOnboarded);
    setOnboardedAt(initialOnboardedAt);
  }, [initialIsOnboarded, initialOnboardedAt]);

  const handleToggle = async () => {
    if (isExpired) return; // Prevent toggling for expired memberships
    
    setIsLoading(true);
    try {
      console.log(`Toggling onboarding for membership ${membershipId} to ${!isOnboarded}`);
      
      const result = await updateMembershipOnboardingCall(
        membershipId,
        !isOnboarded
      );
      
      console.log("API response:", result);
      
      if (result) {
        setIsOnboarded(!isOnboarded);
        setOnboardedAt(!isOnboarded ? new Date().toISOString() : null);
        
        // Call the onUpdate callback to refresh dashboard data
        // if (onUpdate) {
        //   console.log("Calling onUpdate to refresh dashboard data");
        //   onUpdate();
        // }
      }
    } catch (error) {
      console.error("Failed to update onboarding status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '10px',
      minHeight: '24px',
    }}>
      <Checkbox
        type="checkbox"
        checked={isOnboarded}
        onChange={handleToggle}
        disabled={isExpired || isLoading}
      />
      <div style={{ width: '70px', textAlign: 'left' }}>
        <TimestampText style={{ visibility: onboardedAt ? 'visible' : 'hidden' }}>
          {onboardedAt ? formatShortDate(onboardedAt) : " "}
        </TimestampText>
      </div>
    </div>
  );  
};

const formatShortDate = (dateString: string) => {
  const date = new Date(dateString);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export default OnboardingCheckbox;