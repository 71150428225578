import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { FaMapMarkerAlt, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { isTabletOrMobileSize } from "../../utils/queries";
import { Business } from "../../utils/types";
import theme from "../../utils/theme";
import {
  Container,
  GymImage,
  ContentContainer,
  GymName,
  GymAddress,
  FeatureTag,
  FeaturesContainer,
  InvisibleButton,
  GalleryContainer,
  GalleryNavButton
} from "./styles";

interface GymCardProps {
  gymCard: Business;
  onClick?: () => void;
}

const GymCard = ({ gymCard, onClick }: GymCardProps) => {
  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const {
    businessId,
    name,
    gallery,
    amenities,
    address,
    description,
    products
  } = gymCard;

  // Check if gallery is an array and has items
  const hasMultipleImages = Array.isArray(gallery) && gallery.length > 1;
  
  // Get image URL from gallery item
  const getImageUrl = (item: any): string => {
    if (!item) return "";
    
    // If it's directly a string, return it
    if (typeof item === 'string') return item;
    
    // If it's an object with specific properties
    if (item && typeof item === 'object') {
      // Try different possible properties
      if ('imageUrl' in item && typeof item.imageUrl === 'string') 
        return item.imageUrl;
      
      if ('resourceUrl' in item && typeof item.resourceUrl === 'string') 
        return item.resourceUrl;
      
      if ('url' in item && typeof item.url === 'string') 
        return item.url;
      
      if ('src' in item && typeof item.src === 'string') 
        return item.src;
    }
    
    // Fallback to placeholder
    return `https://via.placeholder.com/400x240?text=${encodeURIComponent(name || 'Gym')}`;
  };
  
  // Get current image from gallery
  const currentImage = gallery && Array.isArray(gallery) && gallery.length > 0 
    ? getImageUrl(gallery[currentImageIndex]) 
    : "";
  
  // Get top features (limit to 3)
  const topFeatures = amenities && Array.isArray(amenities) ? amenities.slice(0, 3) : [];

  // Handle gallery navigation
  const nextImage = () => {
    if (Array.isArray(gallery) && gallery.length > 0) {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === gallery.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const prevImage = () => {
    if (Array.isArray(gallery) && gallery.length > 0) {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === 0 ? gallery.length - 1 : prevIndex - 1
      );
    }
  };

  // Stop event propagation so clicking gallery controls doesn't trigger card click
  const handleGalleryButtonClick = (e: React.MouseEvent, callback: () => void) => {
    e.stopPropagation();
    callback();
  };

  return (
    <Container onClick={onClick}>
      <GalleryContainer>
        <GymImage src={currentImage} alt={(name || "") + " image"} />
        
        {hasMultipleImages && (
          <>
            <GalleryNavButton 
              left 
              onClick={(e) => handleGalleryButtonClick(e, prevImage)}
              aria-label="Previous image"
            >
              {/* @ts-ignore */}
              <FaChevronLeft size={16} />
            </GalleryNavButton>
            
            <GalleryNavButton 
              right 
              onClick={(e) => handleGalleryButtonClick(e, nextImage)}
              aria-label="Next image"
            >
              {/* @ts-ignore */}
              <FaChevronRight size={16} />
            </GalleryNavButton>
          </>
        )}
      </GalleryContainer>
      
      <ContentContainer>
        <GymName>{name}</GymName>
        
        <GymAddress>
          <span style={{ marginRight: '5px', display: 'inline-flex' }}>
            {/* @ts-ignore */}
            <FaMapMarkerAlt size={12} />
          </span>
          {address?.fullAddress || "Miami, FL"}
        </GymAddress>
        
        <FeaturesContainer>
          {topFeatures.map((feature, index) => (
            <FeatureTag key={index}>{feature}</FeatureTag>
          ))}
        </FeaturesContainer>
        
        <InvisibleButton aria-label={`View details for ${name || "this gym"}`} />
      </ContentContainer>
    </Container>
  );
};

export default GymCard;