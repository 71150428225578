import styled from "styled-components";

import theme from "../../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
  @media (max-width: 1224px) {
  }
`;
