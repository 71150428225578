import styled from "styled-components";
import theme from "../../../utils/theme";

const { colors, fontSizes, templateColors } = theme;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 20px;
  background-color: ${templateColors.lightGrey};
  width: 100%;
  
  @media (max-width: 768px) {
    padding: 60px 16px;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 36px;
  // font-size: ${fontSizes.bigTitle};
  font-weight: 700;
  margin-bottom: 60px;
  text-align: center;
  color: #000;
  
  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 40px;
  }
`;

export const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  position: relative;
  
  @media (max-width: 768px) {
    padding: 0 10px; /* Add horizontal padding for mobile */
    width: calc(100% - 20px); /* Account for padding */
  }
`;

interface TimelineItemProps {
  isLast: boolean;
}

export const TimelineItem = styled.div<TimelineItemProps>`
  display: flex;
  margin-bottom: ${props => props.isLast ? '0' : '50px'};
  position: relative;
  
  @media (max-width: 768px) {
    margin-left: 10px; /* Add left margin on mobile */
  }
`;

export const TimelineDot = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #25252d;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  flex-shrink: 0;
  
  @media (max-width: 768px) {
    width: 50px; /* Slightly smaller on mobile */
    height: 50px;
  }
`;

export const TimelineConnector = styled.div`
  position: absolute;
  top: 60px;
  left: 30px;
  width: 2px;
  height: calc(100% - 15px);
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
  
  @media (max-width: 768px) {
    top: 50px;
    left: 25px;
  }
`;

export const StepIcon = styled.div`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TimelineContent = styled.div`
  margin-left: 20px;
  padding: 10px 0;
  flex-grow: 1;
  
  @media (max-width: 768px) {
    margin-left: 15px;
    padding-right: 10px; /* Ensure content doesn't touch the right edge */
  }
`;

export const StepTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #25252d;
  
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const StepDescription = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  max-width: 500px;
  
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 1.5;
  }
`;