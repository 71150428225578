import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

import HeroSection from "../HeroSection";
import FooterSection from "../FooterSection";
import HowItWorksSection from "../HowItWorksSection";
import GymHopSection from "../GymHopSection";
import BecomeAPartnerSection from "../BecomeAPartnerSection";
import AboutUsSection from "../AboutUsSection";
import ContactUsSection from "../ContactUsSection";
import NavbarContainer from "../../../components/NavbarContainer";
import LogoSlider from "../LogoSlider";
import { useApi } from "../../../hooks";
import { getAllBusinessCall } from "../../../api/apiOperations";
import {
  Container,
  TopContainer,
  BottomContainer,
  Subcontainer,
} from "./styles";
import { Basket, BasketNoti, LogoLettersBlack, LegacyLogo, Elev8tionLogo, OmegaLogo, PrestigeLogo, GothamLogo, UFCLogo, PurepowerLogo } from "../../../assets/images";

const partnerGymLogos = [
  { id: 1, name: 'Legacy Wynwood', imageUrl: LegacyLogo },
  { id: 2, name: 'Elev8tion Fitness', imageUrl: Elev8tionLogo },
  { id: 3, name: 'Omega Wellness Club', imageUrl: OmegaLogo },
  { id: 4, name: 'Prestige Miami Fitness Club', imageUrl: PrestigeLogo },
  { id: 5, name: 'Gotham Gym Miami', imageUrl: GothamLogo },
  { id: 6, name: 'UFC GYM Midtown', imageUrl: UFCLogo },
  { id: 7, name: 'Purepower Cycle Midtown Miami', imageUrl: PurepowerLogo },
  // Add more logos as needed
];

const Home = () => {
  const { pathname } = useLocation();

  const { request: getAllBusinessCallReq } = useApi<any>({
    apiFunc: getAllBusinessCall,
  });

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    getAllBusinessCallReq();
  }, []);

  return (
    <Container>
      <Subcontainer>
        <NavbarContainer dashboardScreen={false} />
      </Subcontainer>
      {/* <TopContainer> */}
        
      {/* </TopContainer> */}
      <BottomContainer>
        <HeroSection />
        <LogoSlider logos={partnerGymLogos} />
        <HowItWorksSection />
        <GymHopSection />
        <BecomeAPartnerSection />
        <AboutUsSection />
        <ContactUsSection />
        <FooterSection />
      </BottomContainer>
    </Container>
  );
};

export default Home;
