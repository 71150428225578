import React from "react";

import { useMediaQuery } from "react-responsive";
import { HashLink as Link } from "react-router-hash-link";

import EOMButton from "../../../components/EOMButton";
import { partnersLogos } from "../../../utils/arrays";
import { PartnerSectionImage } from "../../../assets/images";
import theme from "../../../utils/theme";
import { isTabletOrMobileSize } from "../../../utils/queries";
import {
  BECOME_A_PARTNER_DESCRIPTION,
  BECOME_A_PARTNER_DESCRIPTION_THREE,
  BECOME_A_PARTNER_DESCRIPTION_TWO,
  BECOME_A_PARTNER_SUBTITLE,
  BECOME_A_PARTNER_TITLE,
  BECOME_A_PARTNER_TITLE_TWO,
  CONTACT_US_TITLE,
} from "../../../utils/strings";
import {
  Container,
  PartnersSection,
  TopContainerPartners,
  Title,
  Subtitle,
  GymLogosContainer,
  ListYourGymSection,
  Logo,
  Image,
  RightContainer,
  TitleListYourGym,
  Description,
  LogoBox,
  ImageContainer,
} from "./styles";

const BecomeAPartnerSection = () => {
  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });

  return (
    <Container>
      <PartnersSection>
        <TopContainerPartners>
          <Title>Our Partners</Title>
          <Subtitle>Partners dedicated to your fitness goals and benefits that extend beyond your workout.</Subtitle>
        </TopContainerPartners>
        <GymLogosContainer>
          {partnersLogos?.map(({ logo, name }: any) => (
            <LogoBox key={name}>
              <Logo src={logo} />
            </LogoBox>
          ))}
        </GymLogosContainer>
      </PartnersSection>
      <ListYourGymSection id="become-a-partner">
        <ImageContainer>
          <Image src={PartnerSectionImage} />
        </ImageContainer>
        <RightContainer>
          <TitleListYourGym>{BECOME_A_PARTNER_TITLE_TWO}</TitleListYourGym>
          <Description>{BECOME_A_PARTNER_DESCRIPTION}</Description>
          <Description>{BECOME_A_PARTNER_DESCRIPTION_TWO}</Description>
          <Description>{BECOME_A_PARTNER_DESCRIPTION_THREE}</Description>
          <Link to="#contact" id="#contact" style={{ textDecoration: "none" }}>
            <EOMButton
              title={CONTACT_US_TITLE}
              onPress={() => null}
              buttonStyle={{
                backgroundColor: theme.templateColors.lightDark,
                borderRadius: 3,
                width: isTabletOrMobile ? 150 : 200,
                marginTop: 40,
              }}
              titleStyle={{
                color: "white",
                fontSize: isTabletOrMobile ? 13 : 16,
                textAlign: "center",
              }}
            />
          </Link>
        </RightContainer>
      </ListYourGymSection>
    </Container>
  );
};

export default BecomeAPartnerSection;