import axios from "axios";
import { BussinesProfile, GalleryUpdate, UpdateProfile } from "../utils/types";
import api from "./client";
import { OnboardingPayload, ConversionPayload } from "./apiOperations";
import { store } from "../redux/store";
import { getAuth } from "firebase/auth";

// ------- User -------

export const getStripeConfig = () => api.get("/v1/config");

export const getUserMembershipStatus = () => api.get("v1/memberships/me");

export const getUser = (idToken: string) =>
  api.get("/v1/users/me", { headers: { Authorization: `Bearer ${idToken}` } });

export const refreshUser = () => api.get("/v1/users/me");

export const updateUserProfile = (data?: UpdateProfile, idToken?: string) =>
  api.put("/v1/users/me", data, {
    headers: { Authorization: `Bearer ${idToken}` },
  });

export const sendWelcomeEmail = async (email: string, firstName: string, gymName: string) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const idToken = await user?.getIdToken();

    if (!idToken) {
      throw new Error("Authorization token missing");
    }

    // console.log("📡 Sending API request to /v1/users/welcome-email", { email, firstName, gymName });

    const response = await api.post("/v1/users/welcome-email", 
      { email, firstName, gymName }, 
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    // console.error("🚨 Error sending welcome email:", error);
    throw error;
  }
};
// ------- Payments -------

export const cancelCurrentMembership = () => api.post("/v1/memberships/cancel");

export const cancelSubscriptionImmediately = (data: { subscriptionId: string }) =>
  api.post("/v1/subscriptions/cancel", data);

export const subscribeToProduct = async (businessProductId: string, stripePromoId?: string) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const idToken = await user?.getIdToken();

    if (!idToken) {
      throw new Error("Authorization token missing");
    }

    const payload: any = { businessProductId };
    if (stripePromoId) {
      payload.stripePromoId = stripePromoId; // Use stripePromoId instead of discountCode
    }

    console.log("📡 Sending API request to /v1/memberships/products/basic", payload);

    const response = await api.post("/v1/memberships/products/basic", payload, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("🚨 Error subscribing to product:", error);
    throw error;
  }
};

export const updateCheckoutSession = async (sessionId: string, stripePromoId: string) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const idToken = await user?.getIdToken();

    if (!idToken) {
      throw new Error("Authorization token missing");
    }

    // console.log("📡 Sending API request to update checkout session", { sessionId, stripePromoId });

    const response = await api.post(
      "/v1/memberships/subscription/update",
      { sessionId, stripePromoId }, // Use stripePromoId instead of discountCode
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    // console.error("🚨 Error updating checkout session:", error);
    throw error;
  }
};

export const subscribeToNextGym = (businessProductId: string) =>
  api.post("/v1/memberships/pick", {
    businessProductId: businessProductId,
  });

export const upgradeToPremium = (businessProductId: string) =>
  api.post("/v1/memberships/upgrade", {
    businessProductId: businessProductId,
  });

  export const attachPaymentMethodToSubscription = async (
    subscriptionId: string,
    paymentMethodId: string,
    idToken: string
  ) => {
    return api.post(
      `/v1/subscriptions/${subscriptionId}/payment-method`,
      { paymentMethodId },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };  

  export const deleteMemberSettings = async (userId: string) => {
    const auth = getAuth();
    const user = auth.currentUser;
    const idToken = await user?.getIdToken();
  
    if (!idToken) {
      throw new Error("Authorization token missing");
    }
  
    return api.post(
      "/v1/member-settings/delete",
      { userId },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };
  
// ------- Business (Gym Owners) -------

export const invitePartner = (tokenData: string) => {
  const params = { token: tokenData };
  return api.get(`/v1/partners/invite`, { params });
};

export const getAllBusiness = () => api.get("/v1/business");

export const getPartnerGymInfo = () => api.get("/v1/business/my-gym");

export const getBusinessDashboardData = (buid: string) =>
  api.get(`/v1/memberships/business/${buid}`);

export const createBusinessProfile = (data: any) =>
  api.post("/v1/business", data);

export const getBusinessProfile = (id: string) => api.get(`/v1/business/${id}`);

export const getBusinessGallery = () =>
  api.get("/v1/business/gallery/signed-url");

export const updateBusinessGallery = (data: any) =>
  api.put("/v1/business/gallery", data);

export const updateMembershipOnboarding = (membershipId: string, payload: OnboardingPayload) =>
  api.put(`/v1/memberships/${membershipId}/onboarding`, payload);

export const updateMembershipConversion = (membershipId: string, payload: ConversionPayload) =>
  api.put(`/v1/memberships/${membershipId}/conversion`, payload);

// ---- Upload Image --> Step 1 ---------

export const uploadImage = (file: any) => {
  const result = api.post("/v1/business/gallery/upload", file, {
    headers: {
      ContentType: "multipart/form-data",
    },
  });
  return result;
};

// ---- Upload Image --> Step 2 ---------
export const updateGallery = (data: GalleryUpdate[]) =>
  api.put("/v1/business/gallery", data);

// ------- Discount Codes -------

// Validate a discount code
export const validateDiscountCode = (code: string, subscriptionId?: string) =>
  api.post("/v1/discount-codes/validate", { code, subscriptionId });

// Record usage of a discount code after successful payment
export const recordDiscountCodeUsage = (data: { code: string; subscriptionId: string, email: string }) =>
  api.post("/v1/discount-codes/record-usage", data);