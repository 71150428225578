import styled from "styled-components";
import TextField from "@mui/material/TextField";

import { styled as MUIstyled } from "@mui/material/styles";

import theme from "../../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${templateColors.lightGrey};
  position: relative;
  padding-top: 8%;
  padding-left: 2%;
  padding-right: 2%;
  font-family: "Inter";
  @media (max-width: 1224px) {
    padding-top: 18%;
  }
`;

export const Title = styled.span`
  font-size: 36px;
  font-weight: 700;
  color: ${templateColors.primaryBlack};
  // text-align: center;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.bigTitleMobile};
    padding-left: 5%;
  }
`;

export const Description = styled.span`
  font-size: ${fontSizes.subtitle};
  line-height: 35px;
  padding-top: 2%;
  color: ${templateColors.primaryBlack};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
    line-height: 30px;
  }
`;

export const Image = styled.img`
  height: 300px;
  width: 300px;
  @media (max-width: 1224px) {
    height: 350px;
    width: 350px;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1224px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  justify-content: center;
  @media (max-width: 1224px) {
    width: 90%;
  }
`;

export const InnerRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  justify-content: center;
  @media (max-width: 1224px) {
    width: 90%;
    padding-top: 3%;
  }
`;

export const SocialBox = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 3%;
  @media (max-width: 1224px) {
    padding-top: 3%;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2%;
  width: 100%;
`;

export const Email = styled.span`
  font-size: ${fontSizes.subtitle};
  line-height: 40px;
  color: ${templateColors.lightDark};
  padding-top: 2%;

  &::before {
    content: "Email: ";
    font-size: ${fontSizes.subtitle};
    font-weight: 700;
    color: ${templateColors.lightDark};

    @media (max-width: 1224px) {
      font-size: ${fontSizes.subtitleMobile};
    }
  }
  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
    line-height: 30px;
  }
`;

export const Ahref = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-decoration: none;
`;

export const Phone = styled.span`
  font-size: ${fontSizes.subtitle};
  line-height: 40px;
  padding-top: 1%;
  color: ${colors.white};

  &::before {
    content: "Phone: ";
    font-size: ${fontSizes.subtitle};
    font-weight: 700;

    @media (max-width: 1224px) {
      font-size: ${fontSizes.subtitleMobile};
    }
  }

  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
    line-height: 30px;
  }
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1224px) {
    padding-right: 10%;
    padding-top: 2%;
  }
`;

export const CustomizedTextField = MUIstyled(TextField)`
  background-color: ${templateColors.lightGrey};
  margin-top: 40px;

  & .MuiInputLabel-formControl {
    color: ${templateColors.lightDark} !important;
  }

  & .MuiInput { 
    &::input {
      &::placeholder {
        color: ${templateColors.lightDark};
      }
    }
  }
  
  & .MuiOutlinedInput-root {
    & fieldset>legend {
      padding-right: 12px;
    }
    &.Mui-focused fieldset {
      border: 1px solid ${templateColors.lightDark};
    }
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${templateColors.lightDark};
  }

  & .MuiInputBase-root {
    color: ${templateColors.lightDark} !important;
  }
 
  & .MuiOutlinedInput-root MuiInputBase {
    color:${colors.white} !important;
  }

  & .MuiFormLabel-root-bhkJJT {
    color:${colors.white} !important;
    font-size: ${fontSizes.subtitle};
    padding-right: 5px
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${templateColors.lightDark};
    :hover {
      border: 1px solid ${colors.white} !important;
    }

    & .Mui-focused .MuiInputAdornment-root {
      border: 1px solid ${templateColors.lightDark};
      
    }
  }
 
`;
