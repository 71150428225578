// src/components/AccountSnapshot/index.tsx
import React from "react";
import { BusinessDashboardUser } from "../../utils/types";
import { isExpired } from "../../utils/utils";
import { getPartnerRate } from "../../utils/partnerRates";
import {
  Container,
  MetricsContainer,
  MetricCard,
  MetricValue,
  MetricLabel
} from "./styles";

// Add a utility function to format currency
const formatCurrency = (amount: number | string): string => {
  // Convert to number if it's a string
  const numAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
  
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(numAmount);
};

const isInCurrentMonth = (dateString: string): boolean => {
  const date = new Date(dateString);
  const now = new Date();
  
  return date.getMonth() === now.getMonth() && 
         date.getFullYear() === now.getFullYear();
};

interface AccountSnapshotProps {
  businessDashboardData: BusinessDashboardUser[];
  partnerName?: string;
  gymPartnerRate?: number;
  showTitle?: boolean;
}

const AccountSnapshot: React.FC<AccountSnapshotProps> = ({ 
  businessDashboardData = [],
  partnerName = '',
  gymPartnerRate,
  showTitle = false
}) => {
  // Determine the appropriate rate based on partner name or use provided gymPartnerRate
  const effectiveRate = gymPartnerRate ?? getPartnerRate(partnerName);

  // Calculate current members (not expired)
  const currentMembers = businessDashboardData.filter(
    user => !isExpired(user.expiresAt || "")
  ).length;

  const currentMonthSignups = businessDashboardData.filter(
    user => user.startedAt && isInCurrentMonth(user.startedAt)
  ).length;

  // Total lifetime members
  const lifetimeMembers = businessDashboardData.length;

  // Calculate conversions
  const conversions = businessDashboardData.filter(
    user => user.isConverted
  ).length;

  // Conversion percentage (if there are any members)
  const conversionRate = lifetimeMembers > 0 
    ? Math.round((conversions / lifetimeMembers) * 100) 
    : 0;

  // Revenue calculations with new formula: $75.00 * (split percentage) + $5.25
  const baseRevenue = 75.00;
  const additionalFee = 5.25;

  // Monthly revenue calculation using the updated formula
  const monthlyRevenue = (currentMonthSignups * (baseRevenue * effectiveRate + additionalFee)).toFixed(2);

  // Lifetime revenue calculation using the updated formula
  const lifetimeRevenue = (lifetimeMembers * (baseRevenue * effectiveRate + additionalFee)).toFixed(2);

  return (
    <Container>
      <MetricsContainer>
        <MetricCard>
          <MetricValue>{currentMembers}</MetricValue>
          <MetricLabel>Current Members</MetricLabel>
        </MetricCard>

        <MetricCard>
          <MetricValue>{lifetimeMembers}</MetricValue>
          <MetricLabel>Lifetime Members</MetricLabel>
        </MetricCard>

        <MetricCard>
          <MetricValue>${formatCurrency(monthlyRevenue)}</MetricValue>
          <MetricLabel>Revenue This Month</MetricLabel>
        </MetricCard>

        <MetricCard>
          <MetricValue>${formatCurrency(lifetimeRevenue)}</MetricValue>
          <MetricLabel>Lifetime Revenue</MetricLabel>
        </MetricCard>

        <MetricCard>
          <MetricValue>{conversions} ({conversionRate}%)</MetricValue>
          <MetricLabel>Total Conversions</MetricLabel>
        </MetricCard>
      </MetricsContainer>
    </Container>
  );
};

export default AccountSnapshot;