import React from "react";
import { FaUserPlus, FaDumbbell, FaExchangeAlt, FaCreditCard } from "react-icons/fa";
import {
  Container,
  SectionTitle,
  TimelineContainer,
  TimelineItem,
  TimelineContent,
  TimelineDot,
  TimelineConnector,
  StepIcon,
  StepTitle,
  StepDescription
} from "./styles";

const HowItWorksSection = () => {
  const steps = [
    {
      number: 1,
      title: "Sign Up",
      description: "Select your first gym/studio and start your membership.",
      iconComponent: "userPlus"
    },
    {
      number: 2,
      title: "Get To Work",
      description: "Enjoy classes and workouts for 30 Days.",
      iconComponent: "dumbbell"
    },
    {
      number: 3,
      title: "Upgrade or Hop",
      description: "Become a full-time member at the gym/studio you selected or move to another!",
      iconComponent: "exchange"
    },
    {
      number: 4,
      title: "No Long-Term Commitments",
      description: "No hidden fees. Cancel anytime.",
      iconComponent: "creditCard"
    }
  ];

  // Render the appropriate icon based on the icon name
  const renderIcon = (iconName: string) => {
    switch (iconName) {
      case "userPlus":
        return (
          <>{/* @ts-ignore */}
            <FaUserPlus size={20} color="#FFFFFF" />
          </>
        );
      case "dumbbell":
        return (
          <>{/* @ts-ignore */}
            <FaDumbbell size={20} color="#FFFFFF" />
          </>
        );
      case "exchange":
        return (
          <>{/* @ts-ignore */}
            <FaExchangeAlt size={20} color="#FFFFFF" />
          </>
        );
      case "creditCard":
        return (
          <>{/* @ts-ignore */}
            <FaCreditCard size={20} color="#FFFFFF" />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Container id="gym-hop">
      <SectionTitle>How It Works</SectionTitle>
      
      <TimelineContainer>
        {steps.map((step, index) => (
          <TimelineItem key={step.number} isLast={index === steps.length - 1}>
            <TimelineDot>
              <StepIcon>{renderIcon(step.iconComponent)}</StepIcon>
            </TimelineDot>
            
            {index !== steps.length - 1 && <TimelineConnector />}
            
            <TimelineContent>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </TimelineContent>
          </TimelineItem>
        ))}
      </TimelineContainer>
    </Container>
  );
};

export default HowItWorksSection;