// src/components/ConversionCheckbox/styles.ts
import styled from "styled-components";
import theme from "../../utils/theme";

export const Checkbox = styled.input`
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 16px;
  height: 16px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export const TimestampText = styled.span`
  font-size: 12px;
  color: #444;
  font-weight: 500;
  height: 16px; /* Fixed height to prevent layout shifting */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 60px; /* Ensures timestamp always takes up space */
`;
