import styled from "styled-components";
import theme from "../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background-color: ${templateColors.backgroundWhite};
  width: 100%;
  max-width: 900px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  margin-top: 16px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
  }
`;

export const CardImageContainer = styled.div`
  flex: 0 0 40%;
  height: auto;
  max-height: 350px;
  overflow: hidden;
  position: relative;
  
  @media (max-width: 768px) {
    flex: 0 0 auto;
    height: 200px;
  }
`;

export const GalleryNavButton = styled.button<{ left?: string; right?: string }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
  
  ${props => props.left && `left: 10px;`}
  ${props => props.right && `right: 10px;`}
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
  
  @media (max-width: 1224px) {
    width: 28px;
    height: 28px;
  }
`;

export const GymImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  display: block;
  min-height: 100%;
`;

export const DetailsSection = styled.div`
  flex: 1;
`;

export const ActionsSection = styled.div`
  margin-top: 20px;
`;

export const InfoRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 16px;
  
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 12px;
  }
`;

export const ContentContainer = styled.div`
  flex: 1;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export const GymName = styled.h3`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 8px;
  color: ${colors.black};
  
  @media (max-width: 1224px) {
    font-size: 18px;
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: ${colors.darkGrey || templateColors.secondaryGrey};
  font-size: 14px;
`;

export const MembershipRow = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const Label = styled.span`
  font-weight: 600;
  margin-right: 8px;
  font-size: ${fontSizes.description};
  color: ${templateColors.secondaryGrey};
  
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const Value = styled.span`
  font-weight: 500;
  font-size: ${fontSizes.description};
  
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const ExpirationRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const ExpirationHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const ExpirationValue = styled.div`
  font-weight: 500;
  margin-left: 22px; /* Same alignment as the icon + spacing */
  color: ${colors.black};
`;

export const NextGymRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const StatusValue = styled.span`
  font-weight: 500;
  font-size: ${fontSizes.description};
  color: ${colors.grey};
  margin-top: 4px;
  
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const SelectionMessage = styled.span`
  font-weight: 500;
  line-height: 21px;
  font-style: italic;
  font-size: ${fontSizes.description};
  margin-bottom: 12px;
  
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
    margin-bottom: 8px;
  }
`;

export const FeaturesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 12px 0;
`;

export const FeatureTag = styled.span`
  background-color: ${templateColors.lightGrey};
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  color: ${colors.darkGrey || templateColors.secondaryGrey};
`;

export const ActionButton = styled.button`
  width: 100%;
  background-color: ${templateColors.lightDark};
  color: ${colors.white};
  border: none;
  border-radius: 4px;
  padding: 12px;
  margin-top: 8px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  @media (max-width: 1224px) {
    padding: 10px;
  }
`;

export const ButtonText = styled.span`
  font-size: ${fontSizes.description};
  
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

// For backwards compatibility, keeping the original styled components
// that might be used elsewhere in the code
export const Tile = GymName;
export const SubTile = styled.span`
  font-weight: 500;
  font-size: ${fontSizes.description};
  color: ${templateColors.lightGrey};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;
export const Title = GymName;
export const Description = Label;
export const DescriptionTwo = Value;
export const DescriptionThree = SelectionMessage;
export const Row = MembershipRow;
export const RowTwo = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-top: 10px;
  margin-bottom: 16px;
  @media (max-width: 1224px) {
    width: 90%;
    margin-top: 7px;
  }
`;
export const RowThree = NextGymRow;
export const BottomTile = ButtonText;
export const Image = styled.img`
  height: 15px;
  width: 15px;
  @media (max-width: 1224px) {
    height: 14px;
    width: 14px;
  }
`;

// These components are not used in the new design but kept for compatibility
export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const RightContainer = styled.div`
  display: none;
`;
export const LeftContainer = styled.div`
  width: 100%;
`;
export const BottomContainer = ActionButton;
export const BottomContainerTwo = ActionButton;
export const BottomTileContainer = styled.div`
  width: 85%;
  @media (max-width: 1224px) {
    padding: 10px;
  }
`;