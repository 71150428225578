import React from "react";

import { useMediaQuery } from "react-responsive";
import { HashLink as Link } from "react-router-hash-link";

import { AboutUsSectionImage } from "../../../assets/images";
import {
  ABOUT_US_DESCRIPTION,
  ABOUT_US_DESCRIPTION_1,
  ABOUT_US_DESCRIPTION_2,
  ABOUT_US_DESCRIPTION_3,
  ABOUT_US_TITLE,
  JOIN_OUR_COMMUNITY_TEAM,
} from "../../../utils/strings";
import EOMButton from "../../../components/EOMButton";
import theme from "../../../utils/theme";
import { isTabletOrMobileSize } from "../../../utils/queries";
import {
  Container,
  TopSection,
  TopContainer,
  Title,
  Subtitle,
  BottomContainer,
  Image,
  RightContainer,
  DescriptionThree,
  Description,
  DescriptionTwo,
  ImageContainer,
  ButtonContainer,
} from "./styles";

const AboutUsSection = () => {
  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });

  return (
    <Container id="about">
      <TopSection>
        <TopContainer>
          <Title>About Us</Title>
          <Subtitle>We created JackedRabbit to provide irresistible value for fitness seekers and to start putting Fitness Partners first.</Subtitle>
        </TopContainer>
      </TopSection>
      <BottomContainer>
        <RightContainer>
          <Description>{ABOUT_US_DESCRIPTION_1}</Description>
          <DescriptionTwo>{ABOUT_US_DESCRIPTION_2}</DescriptionTwo>
          <DescriptionThree>{ABOUT_US_DESCRIPTION_3}</DescriptionThree>
        </RightContainer>
        <ImageContainer>
          <Image src={AboutUsSectionImage} />
        </ImageContainer>
      </BottomContainer>
      <ButtonContainer>
        <Link to="#contact" id="#contact" style={{ textDecoration: "none" }}>
          <EOMButton
            title={JOIN_OUR_COMMUNITY_TEAM}
            onPress={() => null}
            buttonStyle={{
              backgroundColor: theme.templateColors.lightDark,
              borderRadius: 3,
              width: isTabletOrMobile ? "100%" : 200,
              marginTop: 40,
            }}
            titleStyle={{
              color: "white",
              fontSize: isTabletOrMobile ? 13 : 16,
              textAlign: "center",
            }}
          />
        </Link>
      </ButtonContainer>
    </Container>
  );
};

export default AboutUsSection;