import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { FaTimes, FaChevronLeft, FaChevronRight, FaMapMarkerAlt } from "react-icons/fa";

import DashboardHeader from "../../../components/DashboardHeader";
import NavbarContainer from "../../../components/NavbarContainer";
import FooterSection from "../FooterSection";
import {
  SELECT_YOUR_NEXT_GYM,
  JACKED_RABBIT_MODE_DESCRIPTION,
  MEMBERSHIP_UPGRADED,
  ACTIVE_MEMBERSHIPS,
  NO_ACTIVE_MEMBERSHIP,
  PLEASE_COMPLETE_THE_PAYMENT_FLOW,
  OK,
} from "../../../utils/strings";
import InfoCard from "../../../components/InfoCard";
import { DefaultAvatarMember } from "../../../assets/images";
import GymCard from "../../../components/GymCard";
import { getUserMembershipStatusCall, subscribeToNextGymCall } from "../../../api/apiOperations";
import { useApi } from "../../../hooks";
import {
  filterByName,
  formatDate,
  sortGymsByCustomOrder,
} from "../../../utils/utils";
import { deleteUserFirebase } from "../../../auth/operations";
import EOMButton from "../../../components/EOMButton";
import theme from "../../../utils/theme";
import { isTabletOrMobileSize } from "../../../utils/queries";
import { setIsNextGymSelected } from "../../../slices/appSlice"; // Import action
import { Business } from "../../../utils/types"; // Import Business type
import {
  Container,
  SubContainer,
  Tile,
  GymHopContainer,
  GymHopeTitle,
  GymHopeDescription,
  GymHop,
  Box,
  ScrollableContainer,
  BottomContainer,
  ModalOverlay,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  CloseButton,
  ModalBody,
  ModalGallery,
  GalleryImage,
  GalleryNavButton,
  ModalContent,
  ModalSection,
  SectionTitle,
  FeaturesList,
  FeatureItem,
  LocationInfo,
  PriceInfo,
  ModalFooter,
  SelectButton,
  SoldOutButton
} from "./styles";
const { colors, templateColors, fontSizes } = theme;

interface RootState {
  appData: {
    allBusiness: Business[];
    userMembershipStatus: any;
    businessProfile: any;
    membershipUpgraded: boolean;
    isNextGymSelected: boolean;
    userCanDowngradeToBasic: boolean;
  };
  auth: {
    memberLoggedIn: boolean;
    idToken: string;
  };
}

const DashboardMembers: React.FC = () => {
  const dispatch = useDispatch();
  
  const {
    allBusiness,
    userMembershipStatus,
    businessProfile,
    membershipUpgraded,
    isNextGymSelected,
    userCanDowngradeToBasic,
  } = useSelector((state: RootState) => state.appData);

  const { memberLoggedIn } = useSelector((state: RootState) => state.auth);
  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });

  // State for gym modal
  const [selectedGym, setSelectedGym] = useState<Business | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [recentlyVisitedGyms, setRecentlyVisitedGyms] = useState<string[]>([]);
  
  const customOrdererArr = sortGymsByCustomOrder(
    allBusiness,
    "SWEAT440 Midtown Miami"
  );

  const SOLD_OUT_GYMS = ["AIRLAB Fitness", "CKO EDGEWATER", "CrossFit Wynwood"];

  const isGymSoldOut = (gymName: string): boolean => {
    return SOLD_OUT_GYMS.includes(gymName);
  };
  
  const { memberships, settings } = userMembershipStatus || {};

  const { name } = memberships?.active?.business || {};
  const { expiresAt } = memberships?.active || {};
  const currentGymId = memberships?.active?.business?.businessId;

  const { price } = filterByName(businessProfile?.products, "premium") || {};
  const basicProduct = filterByName(businessProfile?.products, "basic") || {};

  const { preTaxCost } = price || 0;
  const basicPrice = basicProduct?.price?.preTaxCost || 0;
  const userWithIncompletePaymentFlow =
    memberLoggedIn && !settings?.currentProductId;

  const upgradePrice = preTaxCost - basicPrice;

  const { request: getUserMembershipStatusCallReq } = useApi<any>({
    apiFunc: getUserMembershipStatusCall,
  });
  
  const { request: selectGymCallReq } = useApi<any>({
    apiFunc: subscribeToNextGymCall,
  });
  
  // Modal functionality
  const handleGymClick = (gym: Business): void => {
    setSelectedGym(gym);
    setCurrentImageIndex(0);
    setShowModal(true);
  };

  const closeModal = (): void => {
    setShowModal(false);
    setSelectedGym(null);
  };
  
  const nextImage = () => {
    if (selectedGym?.gallery && Array.isArray(selectedGym.gallery) && selectedGym.gallery.length > 0) {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === selectedGym.gallery.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const prevImage = () => {
    if (selectedGym?.gallery && Array.isArray(selectedGym.gallery) && selectedGym.gallery.length > 0) {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === 0 ? selectedGym.gallery.length - 1 : prevIndex - 1
      );
    }
  };
  
  // Helper function to get image URL
  const getImageUrl = (item: any): string => {
    if (!item) return "";
    
    if (typeof item === 'string') return item;
    
    if (item && typeof item === 'object') {
      if ('imageUrl' in item && typeof item.imageUrl === 'string') 
        return item.imageUrl;
      
      if ('resourceUrl' in item && typeof item.resourceUrl === 'string') 
        return item.resourceUrl;
      
      if ('url' in item && typeof item.url === 'string') 
        return item.url;
      
      if ('src' in item && typeof item.src === 'string') 
        return item.src;
    }
    
    return "";
  };

// Enhanced cooldown check function that calculates actual remaining time
const getGymCooldownInfo = (gym: Business): { inCooldown: boolean, remainingTime?: string } => {
  // Skip if no membership history
  if (!userMembershipStatus || !userMembershipStatus.memberships) {
    return { inCooldown: false };
  }
  
  // Current gym check using gym name
  const currentGymName = userMembershipStatus.memberships?.active?.business?.name;
  if (gym.name === currentGymName) {
    return { inCooldown: true, remainingTime: "This is your current active gym" };
  }
  
  // Check if the gym is sold out
  if (isGymSoldOut(gym.name)) {
    return { inCooldown: true, remainingTime: "Sold Out" };
  }
  
  // Get ONLY past memberships
  const pastMemberships = userMembershipStatus.memberships?.history || [];
  
  // Sort history chronologically (oldest first)
  const sortedHistory = [...pastMemberships].sort(
    (a: any, b: any) => new Date(a.startedAt).getTime() - new Date(b.startedAt).getTime()
  );
  
  // Find all memberships for this gym
  const gymMemberships = sortedHistory.filter((m: any) => m.business?.name === gym.name);
  
  if (gymMemberships.length === 0) {
    // Never visited this gym before
    return { inCooldown: false };
  }
  
  // Find the most recent membership for this gym
  const mostRecentGymMembership = gymMemberships[gymMemberships.length - 1];
  
  // Find its position in the sorted history
  const gymPosition = sortedHistory.findIndex(m => 
    m.business?.name === mostRecentGymMembership.business?.name && 
    m.startedAt === mostRecentGymMembership.startedAt
  );
  
  // Calculate how many gyms have been visited since
  const gymsVisitedSince = sortedHistory.length - 1 - gymPosition;
  
  // Determine cooldown status
  const isInCooldown = gymsVisitedSince < 3;
  
  if (isInCooldown) {
    const remainingMemberships = 3 - gymsVisitedSince;
    return { 
      inCooldown: true, 
      remainingTime: `${remainingMemberships} more membership${remainingMemberships > 1 ? 's' : ''} required` 
    };
  }
  
  return { inCooldown: false };
};
  
const handleSelectGym = async () => {
  if (!selectedGym) return;
  
  try {
    const basicMembershipProduct = selectedGym.products?.find(p => p.name?.toLowerCase() === 'basic');
    
    if (basicMembershipProduct) {
      const status = await subscribeToNextGymCall(basicMembershipProduct.businessProductId);
      
      if (status === 200) {
        // Success - the subscribeToNextGymCall already updates the state and refreshes membership data
        console.log("Successfully selected gym");
      } else if (status === 2) {
        console.error("Cannot select the same gym again");
        
      } else {
        console.error("Failed to select gym");
        
      }
    } else {
      console.error("No basic membership product found for this gym");
    }
  } catch (error) {
    console.error("Error selecting gym:", error);
  }
  
  closeModal();
};
  
  // Delete user function
  const deleteUser = async () => {
    await deleteUserFirebase();
  };

  const availableGyms = customOrdererArr.filter(gym => {
    // Skip sold-out gyms - these should still be visible
    if (isGymSoldOut(gym.name)) {
      return true;
    }
  
    // Check cooldown status
    const cooldownInfo = getGymCooldownInfo(gym);
  
    // Only keep gyms that are not in cooldown
    return !cooldownInfo.inCooldown;
  });

// Then update your JSX to use availableGyms instead of customOrdererArr:

  // Fetch data on component mount
  useEffect(() => {
    getUserMembershipStatusCallReq();
    
    // Mock implementation of recently visited gyms
    // Replace this with actual API call or data from state
    setRecentlyVisitedGyms([]);
  }, []);

  return (
    <Container>
      <NavbarContainer dashboardScreen />
      {userWithIncompletePaymentFlow ? (
  <SubContainer style={{ height: "500px" }}>
    <Tile>{PLEASE_COMPLETE_THE_PAYMENT_FLOW}</Tile>
    <EOMButton
      title={OK}
      onPress={() => deleteUser()}
      buttonStyle={{
        backgroundColor: theme.templateColors.lightYellow,
        borderRadius: 3,
        width: 100,
        marginTop: 20,
      }}
      titleStyle={{
        color: theme.templateColors.secondaryGrey,
        fontSize: isTabletOrMobile ? 13 : 16,
        textAlign: "center",
        fontWeight: 500,
      }}
    />
  </SubContainer>
) : (
  <>
    <DashboardHeader avatar={DefaultAvatarMember} />
    {!!settings.scheduledCancelAt && (
      <div style={{ 
        width: "100%",
        borderTop: "1px solid #FECACA",
        borderBottom: "1px solid #FECACA",
        backgroundColor: "#FFF5F5",
        padding: "16px 0",
        marginBottom: "24px"
      }}>
        <div style={{
          maxWidth: "1200px",
          margin: "0 auto",
          padding: isTabletOrMobile ? "0 16px" : "0 24px",
          display: "flex",
          flexDirection: "column"
        }}>
          <div style={{ 
            color: "#B91C1C", 
            fontWeight: 600,
            fontSize: isTabletOrMobile ? "15px" : "16px",
            marginBottom: "4px"
          }}>
            Your membership has been scheduled for cancelation.
          </div>
          <p style={{ 
            color: "#4B5563", 
            fontSize: isTabletOrMobile ? "13px" : "14px",
            margin: 0,
            lineHeight: "1.5"
          }}>
            Your access will remain active until <strong>{formatDate(settings.scheduledCancelAt)}</strong>. 
            After that, your subscription will not renew.
          </p>
        </div>          
      </div>
    )}
    
    {/* Always show active membership section */}
    <SubContainer>
      <Tile>{ACTIVE_MEMBERSHIPS}</Tile>
      <InfoCard
        tileOne={name}
        subtile={""}
        title={""}
        description={"Membership expiration: "}
        descriptionTwo={memberships?.active?.product?.name}
        descriptionThree={formatDate(expiresAt)}
        bottomTile={
          membershipUpgraded
            ? MEMBERSHIP_UPGRADED
            : `Reach out to the gym to upgrade!`
        }
        upgradeMembershipPrice={upgradePrice}
      />
    </SubContainer>
    
    <GymHopContainer>
      <GymHopeTitle>{SELECT_YOUR_NEXT_GYM}</GymHopeTitle>
      <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <GymHopeDescription style={{ textAlign: 'center' }}>
          Select your next gym before your monthly membership for your current gym expires.
        </GymHopeDescription>
      </Box>
      
      <ScrollableContainer>
        <BottomContainer>
          {availableGyms.map((gymCard: Business) => (
            <GymCard 
              key={gymCard?.businessId} 
              gymCard={gymCard} 
              onClick={() => handleGymClick(gymCard)}
            />
          ))}
        </BottomContainer>
      </ScrollableContainer>
      
      {showModal && selectedGym && (
        <ModalOverlay onClick={closeModal}>
          <ModalContainer onClick={(e) => e.stopPropagation()}>
            <ModalHeader>
              <ModalTitle>{selectedGym.name}</ModalTitle>
              <CloseButton onClick={closeModal}>
                <span style={{ display: 'flex' }}>
                  {/* @ts-ignore */}
                  <FaTimes size={20} />
                </span>
              </CloseButton>
            </ModalHeader>
            
            <ModalBody>
              <ModalGallery>
                {selectedGym.gallery && Array.isArray(selectedGym.gallery) && selectedGym.gallery.length > 0 && (
                  <GalleryImage 
                    src={getImageUrl(selectedGym.gallery[currentImageIndex])} 
                    alt={`${selectedGym.name || ""} image`} 
                  />
                )}
                
                {selectedGym.gallery && Array.isArray(selectedGym.gallery) && selectedGym.gallery.length > 1 && (
                  <>
                    <GalleryNavButton left onClick={prevImage}>
                      {/* @ts-ignore */}
                      <span style={{ display: 'flex' }}><FaChevronLeft size={18} /></span>
                    </GalleryNavButton>
                    
                    <GalleryNavButton right onClick={nextImage}>
                      {/* @ts-ignore */}
                      <span style={{ display: 'flex' }}><FaChevronRight size={18} /></span>
                    </GalleryNavButton>
                  </>
                )}
              </ModalGallery>
              
              <ModalContent>
                <ModalSection>
                  <SectionTitle>About {selectedGym.name}</SectionTitle>
                  <p>{selectedGym.description || `Experience premium fitness at ${selectedGym.name}. State-of-the-art equipment and expert trainers await you.`}</p>
                </ModalSection>
                
                <ModalSection>
                  <SectionTitle>Features</SectionTitle>
                  <FeaturesList>
                    {(selectedGym.amenities || []).map((feature, index) => (
                      <FeatureItem key={index}>{feature}</FeatureItem>
                    ))}
                  </FeaturesList>
                </ModalSection>
                
                <ModalSection>
                  <SectionTitle>Location</SectionTitle>
                  <LocationInfo>
                    {selectedGym.address?.fullAddress}
                  </LocationInfo>
                </ModalSection>
              </ModalContent>
            </ModalBody>
            <ModalFooter>
              {(() => {
                if (isGymSoldOut(selectedGym.name)) {
                  return (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <PriceInfo>$75/month</PriceInfo>
                      <SoldOutButton disabled>Sold Out</SoldOutButton>
                    </div>
                  );
                }

                const cooldownInfo = getGymCooldownInfo(selectedGym);
                if (cooldownInfo.inCooldown) {
                  return (
                    <div style={{ width: '100%', textAlign: 'center', color: '#006BFF' }}>
                      <p>This gym is unavailable due to the 3-month cooldown period.</p>
                      <p style={{ fontWeight: 'bold' }}>{cooldownInfo.remainingTime}</p>
                    </div>
                  );
                }

                return (
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <PriceInfo>$75/month</PriceInfo>
                    <SelectButton onClick={handleSelectGym}>
                      Select as Next Facility
                    </SelectButton>
                  </div>
                );
              })()}
            </ModalFooter>
          </ModalContainer>
        </ModalOverlay>
      )}
    </GymHopContainer>
  </>
)}
      <FooterSection />
    </Container>
  );
};

export default DashboardMembers;