import React from "react";
import { useMediaQuery } from "react-responsive";
import { HashLink as Link } from "react-router-hash-link";
// Import icons directly like in your working example
import { FaKey, FaCalendarAlt, FaExchangeAlt } from "react-icons/fa";

import {
  HERO_BUTTON,
  HERO_SUBTITLE,
  HERO_SUBTITLE_MOBILE_1,
  HERO_SUBTITLE_MOBILE_2,
  HERO_SUBTITLE_MOBILE_3,
  HERO_TITLE,
} from "../../../utils/strings";
import EOMButton from "../../../components/EOMButton";
import theme from "../../../utils/theme";
import { isTabletOrMobileSize } from "../../../utils/queries";
import {
  Container,
  IntroContainer,
  Title,
  SubTitle,
  Overlay,
  SubTitleBullet,
  SubtitleBox,
  ButtonWrapper,
  // New styled components for mobile
  BenefitCardsContainer,
  BenefitCard,
  IconContainer,
  BenefitText
} from "./styles";

// Define the type for icon names
type IconType = "key" | "calendar" | "exchange";

const HeroSection = () => {
  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });

  // Custom icon rendering function with proper type
  const renderIcon = (iconType: IconType) => {
    switch (iconType) {
      case "key":
        return (
          <>{/* @ts-ignore */}
            <FaKey size={20} color="#FFFFFF" />
          </>
        );
      case "calendar":
        return (
          <>{/* @ts-ignore */}
            <FaCalendarAlt size={20} color="#FFFFFF" />
          </>
        );
      case "exchange":
        return (
          <>{/* @ts-ignore */}
            <FaExchangeAlt size={20} color="#FFFFFF" />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Container id="hero">
      <Overlay />
      <IntroContainer>
        <Title>{HERO_TITLE}</Title>
        
        {isTabletOrMobile ? (
          <>
            {/* Original bullet points (now hidden via CSS) */}
            <SubtitleBox>
              <SubTitleBullet>• {HERO_SUBTITLE_MOBILE_1}</SubTitleBullet>
              <SubTitleBullet>• {HERO_SUBTITLE_MOBILE_2}</SubTitleBullet>
              <SubTitleBullet>• {HERO_SUBTITLE_MOBILE_3}</SubTitleBullet>
            </SubtitleBox>
            
            {/* New card-based layout for mobile using your icon pattern */}
            <BenefitCardsContainer>
              <BenefitCard>
                <IconContainer>
                  {renderIcon("key")}
                </IconContainer>
                <BenefitText>{HERO_SUBTITLE_MOBILE_1}</BenefitText>
              </BenefitCard>
              
              <BenefitCard>
                <IconContainer>
                  {renderIcon("calendar")}
                </IconContainer>
                <BenefitText>{HERO_SUBTITLE_MOBILE_2}</BenefitText>
              </BenefitCard>
              
              <BenefitCard>
                <IconContainer>
                  {renderIcon("exchange")}
                </IconContainer>
                <BenefitText>{HERO_SUBTITLE_MOBILE_3}</BenefitText>
              </BenefitCard>
            </BenefitCardsContainer>
          </>
        ) : (
          <SubTitle>{HERO_SUBTITLE}</SubTitle>
        )}
        
        <ButtonWrapper>
          <Link
            to={"#our-facilities"}
            smooth
            style={{ textDecoration: "none", zIndex: 550, alignSelf: "center" }}
          >
            <EOMButton
              title={HERO_BUTTON}
              onPress={() => null}
              buttonStyle={{
                backgroundColor: theme.templateColors.lightDark,
                borderRadius: 3,
                width: isTabletOrMobile ? 226 : 200,
                marginTop: 20,
              }}
              titleStyle={{
                color: theme.colors.white,
                fontSize: isTabletOrMobile ? 14 : 16,
                textAlign: "center",
                fontWeight: 500,
              }}
            />
          </Link>
        </ButtonWrapper>
      </IntroContainer>
    </Container>
  );
};

export default HeroSection;

// NEW HERO TO IMPLEMENT WHEN FIGMA IS DONE

// import React from "react";
// import { useMediaQuery } from "react-responsive";
// import { HashLink as Link } from "react-router-hash-link";
// import { isTabletOrMobileSize } from "../../../utils/queries";
// import {
//   Container,
//   ContentCard,
//   Logo,
//   SubTitle,
//   ButtonWrapper,
//   Button,
//   ButtonText,
//   CollageBackground
// } from "./styles";

// // Import the collage image and logo
// import DesktopCollage from "../../../assets/images/Desktop Hero (Collage Only).jpg";
// import MobileCollage from "../../../assets/images/MobileSite Hero (Collage Only).jpg";
// import LogoImage from "../../../assets/images/logo-letters-black.png";

// const HeroSection = () => {
//   const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });

//   return (
//     <Container id="hero">
//       <CollageBackground 
//         src={isTabletOrMobile ? MobileCollage : DesktopCollage} 
//         alt="JackedRabbit gyms collage"
//       />
//       <ContentCard>
//         <Logo src={LogoImage} alt="JackedRabbit" />
//         <SubTitle>
//           A new gym or studio every month for $75!
//         </SubTitle>
//         <ButtonWrapper>
//           <Link
//             to={"#our-facilities"}
//             smooth
//             style={{ textDecoration: "none", width: "100%" }}
//           >
//             <Button>
//               <ButtonText>Select Your Gym</ButtonText>
//             </Button>
//           </Link>
//         </ButtonWrapper>
//       </ContentCard>
//     </Container>
//   );
// };

// export default HeroSection;