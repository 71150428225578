import styled from "styled-components";
import theme from "../../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${templateColors.lightGrey};
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: ${templateColors.lightGrey};
  width: 100%;
  padding-left: 20%;

  @media (max-width: 1224px) {
    justify-content: flex-start;
    width: 89%;
    padding-left: 0%;
    margin-top: 5px;
  }
`;

export const Tile = styled.span`
  color: ${colors.black};
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  @media (max-width: 1224px) {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
  }
`;

export const GymHopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${templateColors.lightGrey};
  width: 100%;
  max-width: 85%;
  overflow: hidden;
  border-radius: 12px;
  margin: 40px 0;
  padding: 32px 16px;

  @media (max-width: 1224px) {
    margin: 20px 0;
    max-width: 95%;
    padding: 24px 8px;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1224px) {
    width: 80%;
    text-align: center;
  }
`;

export const GymHop = styled.div`
  background-color: ${templateColors.lightGrey};
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  position: relative;
  height: 600px;
  width: 100%;
  z-index: 20;
  margin-top: 3%;
  overflow-y: scroll;
  gap: 2px;
  &::-webkit-scrollbar {
    width: 0px; /* Remove the scrollbar width */
    height: 0px; /* Remove the scrollbar height */
  }

  @media (max-width: 1224px) {
    max-width: 90%;
    overflow-x: hidden;
    justify-content: center;
  }
`;

export const GymHopeTitle = styled.span`
  color: ${colors.black};
  font-weight: 700;
  font-size: 28px;
  line-height: 1.2;
  margin-bottom: 8px;
  text-align: center;
  
  @media (max-width: 1224px) {
    font-size: 22px;
  }
`;

export const GymHopeSubtitle = styled.span`
  font-weight: 500;
  font-size: ${fontSizes.subtitle};
  color: ${templateColors.lightYellow};

  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
    margin-top: 15px;
  }
`;

export const GymHopeDescription = styled.span`
  font-weight: 400;
  font-size: ${fontSizes.description};
  color: ${templateColors.grey};
  text-align: center;
  max-width: 80%;
  margin-bottom: 16px;
  
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
    line-height: 1.4;
    max-width: 90%;
  }
`;

export const ScrollableContainer = styled.div`
  width: 100%;
  max-height: 600px;
  overflow-y: auto;
  scrollbar-width: thin;
  
  /* Custom scrollbar styling for webkit browsers */
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }
  
  @media (max-width: 768px) {
    max-height: 500px;
  }
`;

export const BottomContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  width: 100%;
  padding: 16px 8px;
  
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

// Modal Components
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  padding: 0 20px;
  overflow-y: auto;
`;

export const ModalContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  width: 90%;
  max-width: 1000px;
  height: auto;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
  margin: 120px auto 60px;
  
  @media (max-width: 768px) {
    width: 90%;
    max-width: 500px;
    margin: 100px auto 60px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #eee;
`;

export const ModalTitle = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: #000;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  
  &:hover {
    opacity: 0.7;
  }
`;

export const ModalBody = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  
  @media (min-width: 769px) {
    flex-direction: row;
  }
`;

export const ModalGallery = styled.div`
  position: relative;
  background-color: #f5f5f5;
  
  @media (min-width: 769px) {
    width: 50%;
    height: 400px;
  }
  
  @media (max-width: 768px) {
    height: 240px;
    width: 100%;
  }
`;

export const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const GalleryNavButton = styled.button<{ left?: boolean; right?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${props => props.left ? 'left: 15px;' : ''}
  ${props => props.right ? 'right: 15px;' : ''}
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.95);
  }
`;

export const ModalContent = styled.div`
  padding: 20px;
  box-sizing: border-box;
  
  @media (min-width: 769px) {
    width: 50%;
    overflow-y: auto;
  }
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ModalSection = styled.div`
  margin-bottom: 24px;
`;

export const SectionTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 0 0 12px 0;
`;

export const FeaturesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  
  @media (max-width: 768px) {
    padding-right: 8px;
  }
`;

export const FeatureItem = styled.div`
  background-color: #f5f5f5;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
`;

export const LocationInfo = styled.div`
  font-size: 16px;
  color: #555;
`;

export const PriceInfo = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #000;
`;

export const ModalFooter = styled.div`
  padding: 20px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    align-items: center;
  }
  
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

export const SelectButton = styled.button`
  background-color: ${templateColors.lightDark};
  color: #FFF;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    opacity: 0.9;
  }
  
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const SoldOutButton = styled(SelectButton)`
  background-color: #9e9e9e;
  cursor: not-allowed;
  opacity: 0.8;
  
  &:hover {
    opacity: 0.8;
  }
`;
