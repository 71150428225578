import styled from "styled-components";
import theme from "../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

interface DiscountMessageProps {
  isError?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 15px 0;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  margin-top: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const DiscountInput = styled.input`
  flex: 1;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 1.4;
  background-color: ${templateColors.lightGrey};
  border: 1px solid ${templateColors.lightDark};
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: ${templateColors.lightDark};
  }
  
  @media (max-width: 1224px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const ApplyButton = styled.button<{ disabled?: boolean }>`
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  background-color: ${({ disabled }) =>
    disabled ? templateColors.grey : templateColors.lightDark};
  color: ${colors.white};
  border: none;
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  width: auto;
  white-space: nowrap;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const DiscountMessage = styled.div<DiscountMessageProps>`
  margin-top: 8px;
  font-size: ${fontSizes.description};
  color: ${(props) => (props.isError ? colors.red : templateColors.lightDark)};
`;

export const AppliedDiscount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  background-color: rgba(144, 238, 144, 0.2);
  border-radius: 3px;
  font-size: ${fontSizes.description};
  
  @media (max-width: 1224px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const RemoveButton = styled.button`
  background: none;
  border: none;
  color: ${templateColors.lightDark};
  font-size: ${fontSizes.description};
  cursor: pointer;
  text-decoration: underline;
  
  @media (max-width: 1224px) {
    margin-top: 8px;
    align-self: flex-end;
  }
`;